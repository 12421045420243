import * as React from 'react';

function SvgGreyTelephone(props) {
  return (
    <svg width={10} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        clipPath="url(#grey-telephone_svg__clip0)"
        stroke="#8B8A8A"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M.5 13V1.5c0-.6.4-1 1-1h6.4c.6 0 1 .4 1 1V13c0 .6-.4 1-1 1H1.5c-.6 0-1-.4-1-1zM.5 10.4h8.4M4 12.1h1.5M4.6 2.2h.3" />
      </g>
      <defs>
        <clipPath id="grey-telephone_svg__clip0">
          <path fill="#fff" d="M0 0h9.4v14.5H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgGreyTelephone;
