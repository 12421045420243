import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import DocumentIcon from 'images/CaseStudy/DocumentIcon';
import LightbulbIcon from 'images/CaseStudy/LightbulbIcon';
import TickIcon from 'images/CaseStudy/TickIcon';

const CaseStudyInfo = ({ viewportWidth, pathname, theBriefText, ourSolutionText, resultsText }) => {
  return (
    <Wrapper pathname={pathname}>
      {theBriefText && (
        <InfoBlock>
          <SVGAndHeaderWrapper>
            <DocumentIcon />
            <H2>The Brief</H2>
          </SVGAndHeaderWrapper>
          <Text>{theBriefText}</Text>
        </InfoBlock>
      )}
      {ourSolutionText && (
        <InfoBlock>
          <SVGAndHeaderWrapper>
            <LightbulbIcon />
            <H2>Our Solution</H2>
          </SVGAndHeaderWrapper>
          <Text dangerouslySetInnerHTML={{ __html: ourSolutionText }} />
        </InfoBlock>
      )}
      {resultsText && (
        <InfoBlock>
          <SVGAndHeaderWrapper>
            <TickIcon />
            <H2>Results</H2>
          </SVGAndHeaderWrapper>
          <Text>
            {resultsText.map((result) => (
              <span key={result}>{result}</span>
            ))}
          </Text>
        </InfoBlock>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-wrap: wrap;
  flex-flow: column wrap;
  margin: 6.813em auto
    ${({ pathname }) =>
      pathname === '/portfolio/get-broadcasting-this-is-livestream/' ? '0' : '3em'};
  max-width: 1160px;
  padding: 0 5%;
  width: 100%;

  @media screen and (min-width: 950px) {
    flex-direction: row;
  }
`;

const InfoBlock = styled.div`
  font-family: proxima-nova, sans-serif;

  @media screen and (max-width: 949px) {
    &:not(:last-of-type) {
      margin-bottom: 2.5em;
    }
  }

  @media screen and (min-width: 950px) {
    max-width: 50%;

    &:last-of-type {
      margin-top: 2em;
      max-width: 100%;
    }
  }
`;

const SVGAndHeaderWrapper = styled.div`
  align-items: center;
  display: flex;

  @media screen and (max-width: 465px) {
    margin-bottom: 0.875rem;
  }

  svg {
    margin-right: 1.25rem;

    @media screen and (min-width: 950px) {
      margin-right: 2.5rem;
    }
  }
`;

const H2 = styled.h2`
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 0.05em;

  @media screen and (min-width: 466px) {
    font-size: 2.27rem;
  }
`;

const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.4em;

  @media screen and (min-width: 466px) {
    font-size: 1.15rem;
    margin: 0 2.5em 0 calc(60px + 1.25rem);
  }

  @media screen and (min-width: 950px) {
    margin: 0 2.5em 0 calc(60px + 2.5rem);
  }

  a {
    text-decoration: none;
    color: #e4097e;
  }

  span {
    color: #e4097e;
    display: block;

    @media screen and (min-width: 950px) {
      display: inline;

      &:not(:last-child) {
        margin-right: 1.25em;
      }
    }
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(CaseStudyInfo);
