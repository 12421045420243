import * as React from 'react';

function SvgVideoIcon(props) {
  return (
    <svg
      width={114}
      height={114}
      viewBox="0 0 197 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M98.5 196C152.348 196 196 152.348 196 98.5C196 44.6522 152.348 1 98.5 1C44.6522 1 1 44.6522 1 98.5C1 152.348 44.6522 196 98.5 196Z"
        stroke="#000"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M160.12 92.62L142.51 104.36C140.66 105.6 139.13 108.45 139.13 110.68V118.81C139.13 121.04 140.66 123.89 142.51 125.12L160.12 136.86C161.98 138.1 163.5 137.29 163.5 135.05V94.43C163.5 92.19 161.98 91.39 160.12 92.62Z"
        className="fill"
        fill="#000"
      />
      <path
        d="M94.9 74.12H85.85C80.22 83.78 69.86 90.37 57.87 90.37C55.05 90.37 52.37 89.89 49.75 89.21V135.06C49.75 137.29 51.58 139.12 53.81 139.12H74.12L57.87 163.5H65.99L90.37 139.12L114.75 163.5H122.88L106.63 139.12H126.94C129.18 139.12 131 137.29 131 135.06V89.21C128.4 89.89 125.7 90.37 122.88 90.37C110.91 90.37 100.54 83.78 94.9 74.12"
        className="fill"
        fill="#000"
      />
      <path
        d="M82.2502 57.88C82.2502 44.42 71.3302 33.51 57.8702 33.51C44.4102 33.51 33.4902 44.43 33.4902 57.88C33.4902 71.33 44.4102 82.26 57.8702 82.26C71.3302 82.26 82.2502 71.34 82.2502 57.88"
        className="fill"
        fill="#000"
      />
      <path
        d="M122.88 82.25C136.33 82.25 147.25 71.33 147.25 57.88C147.25 44.43 136.33 33.5 122.88 33.5C109.43 33.5 98.5 44.42 98.5 57.88C98.5 71.34 109.42 82.25 122.88 82.25Z"
        className="fill"
        fill="#000"
      />
    </svg>
  );
}

export default SvgVideoIcon;
