import * as React from 'react';

function SvgCarouselPlayButton(props) {
  return (
    <svg width={89} height={90} viewBox="0 0 89 90" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_d_1261_48)">
        <circle
          cx="44.1504"
          cy="41.0001"
          r="39.1504"
          fill="url(#paint0_linear_1261_48)"
          fill-opacity="0.3"
        />
        <path
          d="M44.8497 80.1505C66.4719 80.1505 84.0001 62.6222 84.0001 41.0001C84.0001 19.3779 66.4719 1.84961 44.8497 1.84961C23.2275 1.84961 5.69922 19.3779 5.69922 41.0001C5.69922 62.6222 23.2275 80.1505 44.8497 80.1505Z"
          fill="url(#paint1_linear_1261_48)"
          fill-opacity="0.3"
          stroke="white"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M64.4956 40.9152L34.8667 24.6222L34.125 58.4963L64.4956 40.9152Z"
          fill="url(#paint2_linear_1261_48)"
          fill-opacity="0.3"
          stroke="white"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M42.4528 28.7512L41.918 54.022"
          stroke="white"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M51.5495 34.0609L51.2041 48.6737"
          stroke="white"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1261_48"
          x="0.699219"
          y="0.849609"
          width="88.3008"
          height="88.3009"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1261_48" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1261_48"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1261_48"
          x1="64.7743"
          y1="19.3275"
          x2="23.177"
          y2="78.0532"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#E4097E" />
          <stop offset="1" stop-color="#FFEF00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1261_48"
          x1="65.4736"
          y1="19.3275"
          x2="23.8762"
          y2="78.0532"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#E4097E" />
          <stop offset="1" stop-color="#FFEF00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1261_48"
          x1="29.878"
          y1="35.4234"
          x2="59.3116"
          y2="40.9663"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#E4097E" />
          <stop offset="1" stop-color="#FFEF00" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgCarouselPlayButton;
