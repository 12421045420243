import * as React from 'react';

function SvgTwitterIcon(props) {
  return (
    <svg width={12} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.396 1.533c-.37.157-.763.247-1.157.314.185-.022.463-.359.579-.494.162-.202.3-.426.393-.673 0-.023.023-.045 0-.068h-.07c-.439.225-.879.382-1.365.517-.046 0-.07 0-.092-.023-.047-.045-.07-.09-.116-.112A3.292 3.292 0 008.897.59 2.73 2.73 0 007.855.433a2.411 2.411 0 00-.972.269c-.3.157-.578.36-.787.629-.231.27-.393.584-.462.898-.07.315-.07.63-.024.966 0 .045 0 .067-.046.045C3.69 2.97 2.14 2.32.866.904.82.837.773.837.727.904c-.555.809-.278 2.111.417 2.74.092.09.185.18.3.247-.046 0-.509-.045-.902-.247C.496 3.6.449 3.622.449 3.69c0 .09 0 .157.023.27.116.808.695 1.572 1.482 1.864.092.045.208.067.3.09-.185.045-.37.067-.902.022-.07-.022-.093.023-.07.09.394 1.033 1.25 1.348 1.875 1.527.093.023.162.023.255.045l-.023.023c-.209.314-.95.539-1.273.651-.625.202-1.296.314-1.944.247-.116-.022-.116-.022-.162 0-.024.022 0 .045.023.067.139.09.277.158.393.225.417.202.856.382 1.296.494 2.338.629 4.976.157 6.712-1.527 1.389-1.325 1.851-3.167 1.851-4.986 0-.068.093-.112.14-.157.346-.27.647-.584.902-.921.07-.068.046-.157.046-.18.07-.045.07-.022.023 0z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgTwitterIcon;
