import React from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';

const Title = ({
  pathname,
  text,
  style = {
    color: '#000',
    fontWeight: 700
  }
}) => {
  const splitText = text.split('<br />');
  return (
    <Wrapper>
      {splitText.map((_text) => (
        <Text
          key={_text}
          style={style}
          as={pathname?.match(/[portfolio|services|contact-us]/) ? 'h1' : 'h2'}>
          {_text}
          <MultiColouredBottomBorder />
        </Text>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const MultiColouredBottomBorder = styled.div`
  background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
  height: 3px;
  bottom: 2px;
  left: -0.75em;
  position: relative;
  width: calc(100% + 1.5em);
`;

const Text = styled.h2`
  font-size: calc(1.65rem + 2vw);
  margin-bottom: 0.125em;
  padding: 0 0.75em 0;
  text-align: center;
  ${({ style }) => style};

  @media screen and (min-width: 1200px) {
    font-size: 3rem;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Title);
