import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

const Banner = ({ viewportWidth }) => (
  <Wrapper>
    <StaticQuery
      query={graphql`
        {
          cameraFilmingPeopleInStudio: file(
            relativePath: { eq: "Training/camera_filming_people_in_studio.jpg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ cameraFilmingPeopleInStudio }) => (
        <BannerImg
          imgStyle={{ objectPosition: 'top right' }}
          fluid={cameraFilmingPeopleInStudio.childImageSharp.fluid}
        />
      )}
    />
    {viewportWidth < 1200 && <Underlay />}
    <TextContainer>
      <h2>Introduction</h2>
      <MultiColouredHR />
      <h1>
        Corporate Communications
        <br />
        in the Digital Age Series
      </h1>
    </TextContainer>
  </Wrapper>
);
const Wrapper = styled.div`
  position: relative;

  > img {
    left: 0;
    position: absolute;
    top: 0;
  }
`;

const BannerImg = styled(Img)`
  min-height: 456px;
`;

const Underlay = styled.div`
  background-color: rgba(000, 000, 000, 0.375);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const TextContainer = styled.div`
  bottom: 14%;
  padding: 0 4.5%;
  position: absolute;

  @media screen and (min-width: 375px) {
    bottom: 9%;
    padding: 0 9%;
  }

  h1,
  h2 {
    color: #fff;
  }

  h1 {
    font-family: proxima-nova, sans-serif;
    font-size: calc(1.75rem + 3.35vw);
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: center;

    @media screen and (min-width: 375px) {
      text-align: left;
    }

    @media screen and (min-width: 950px) {
      font-size: 3.75rem;
    }
  }

  h2 {
    font-size: calc(1.2rem + 0.7vw);
    margin-bottom: 0.275em;
    text-align: center;
    text-transform: uppercase;

    @media screen and (min-width: 375px) {
      text-align: left;
    }

    @media screen and (min-width: 950px) {
      font-size: 1.625rem;
    }
  }
`;

const MultiColouredHR = styled.div`
  background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
  height: 3px;
  margin-bottom: 1em;
  width: 100%;

  @media screen and (min-width: 430px) {
    width: 335px;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Banner);
