import * as React from 'react';

function SvgFbIcon(props) {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={16} cy={16} r={16} fill="#C4C4C4" />
      <g clipPath="url(#fb-icon_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6 16.1v7.7c0 .1.1.2.2.2h2.9c.1 0 .2-.1.2-.2v-7.9H19c.1 0 .2-.1.2-.2l.2-2.4c0-.1-.1-.2-.2-.2h-2.3v-1.6c0-.4.3-.7.7-.7h1.6c.1 0 .2-.1.2-.2V8.2c0-.1-.1-.2-.2-.2h-2.7c-1.6 0-2.9 1.3-2.9 2.9v2.3h-1.4c-.1 0-.2.1-.2.2v2.4c0 .1.1.2.2.2h1.4v.1z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="fb-icon_svg__clip0">
          <path fill="#fff" transform="translate(12 8)" d="M0 0h7.4v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgFbIcon;
