import * as React from 'react';

function SvgVimeoicon(props) {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={16} cy={16} r={16} fill="#C4C4C4" />
      <path
        d="M23.992 13.239c-.077 1.575-1.232 3.732-3.467 6.471-2.311 2.86-4.267 4.29-5.867 4.29-.99 0-1.83-.872-2.515-2.617l-1.37-4.8c-.509-1.745-1.055-2.617-1.64-2.617-.127 0-.57.254-1.333.763l-.8-.981c.831-.697 1.657-1.4 2.476-2.109 1.118-.92 1.956-1.406 2.515-1.454 1.32-.121 2.134.74 2.438 2.581.33 1.988.56 3.224.686 3.708.38 1.648.8 2.472 1.257 2.472.356 0 .889-.533 1.6-1.6.712-1.066 1.093-1.878 1.143-2.435.101-.921-.28-1.382-1.143-1.382a3.43 3.43 0 00-1.257.254c.838-2.593 2.439-3.853 4.8-3.78 1.753.049 2.579 1.127 2.477 3.236z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgVimeoicon;
