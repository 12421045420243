import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

const Testimonials = ({ viewportWidth }) => (
  <StaticQuery
    query={graphql`
      {
        televisionStudio: file(relativePath: { eq: "Training/television_studio.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={({ televisionStudio }) => (
      <Wrapper>
        <TelevisionStudioImg fluid={televisionStudio.childImageSharp.fluid} />
        <TextContainer>
          <h2>What our clients say about the course</h2>
          <GreyUnderlay>
            <p><em>No one should be allowed on camera until they’ve<br />completed this course!</em> - CEO, Insurance Industry</p>
            <p><em>Course Content well tailored to “Client Company”<br />practical needs</em> - Product Manager</p>
            <p><em>The training definitely gave me the confidence that I needed<br />to do the webcast last week</em> - Global Brand Leader</p>
            <p><em>Venue is ideal, secure and relaxed and the team are great,<br />I thought it was brilliant</em> - Operations Manager, Dublin</p>
            <p><em>The presenter&apos;s depth of experience and knowledge of the area<br />shone through</em> - Communications Director, Dublin</p>
          </GreyUnderlay>
        </TextContainer>
      </Wrapper>
    )}
  />
);
const Wrapper = styled.div`
  position: relative;
`;

const TelevisionStudioImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 722px;
  max-height: 934px;
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  padding: 0 2.96%;
  width: 100%;

  @media screen and (min-width: 1060px) {
    padding: 0 8.96%;
  }

  h2 {
    color: #fff;
    font-family: proxima-nova, sans-serif;
    font-weight: 600;
    font-size: calc(1.25rem + 0.5vw);
    margin: 0.625em 0;
    text-align: center;

    @media screen and (min-width: 420px) {
      text-align: left;
    }

    @media screen and (min-width: 1425px) {
      font-size: 2.5rem;
    }
  }
`;

const GreyUnderlay = styled.div`
  background: rgba(255, 255, 255, 0.85);
  padding: 4% 4.5% 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 608px;

  p {
    text-align: center;
    font-size: calc(1rem + 1vw);
    color: #000;
    font-weight: 300;
    margin-bottom: 1em;

    @media screen and (min-width: 1425px) {
      font-size: 2.25rem;
    }
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Testimonials);
