import * as React from 'react';

function SvgInstaicon(props) {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={16} cy={16} r={16} fill="#C4C4C4" />
      <path
        d="M20.013 12.887a.889.889 0 10-.002-1.778.889.889 0 00.002 1.778zM16.06 12.255c-2.061 0-3.751 1.693-3.751 3.76 0 2.065 1.69 3.758 3.752 3.758s3.752-1.693 3.752-3.759-1.69-3.76-3.752-3.76zm0 6.17a2.423 2.423 0 01-2.405-2.41c0-1.32 1.088-2.411 2.406-2.411a2.423 2.423 0 012.406 2.41c0 1.32-1.089 2.41-2.406 2.41z"
        fill="#fff"
      />
      <path
        d="M19.038 23.619h-6.072c-2.52 0-4.583-2.066-4.583-4.592v-6.055c0-2.525 2.062-4.591 4.583-4.591h6.072c2.52 0 4.583 2.066 4.583 4.591v6.084c-.029 2.525-2.062 4.563-4.583 4.563zM12.966 9.816a3.145 3.145 0 00-3.151 3.156v6.084c0 1.722 1.404 3.157 3.15 3.157h6.073a3.163 3.163 0 003.15-3.157v-6.084c0-1.721-1.403-3.156-3.15-3.156h-6.072z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgInstaicon;
