import React from 'react';
import styled from 'styled-components';

export default ({
  children,
  style = {
    maxWidth: '940px',
    color: '#000'
  }
}) => (
  <HeroText style={style}>
    {children}
  </HeroText>
);

const HeroText = styled.p`
  font-size: calc(0.75rem + 2vw);
  line-height: 1.5em;
  margin: 2.25em auto 3.2em;
  padding: 0 1.75em;
  text-align: center;
  ${({ style }) => style};

  @media screen and (min-width: 1200px) {
    font-size: 2.25rem;
  }
`;
