import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';

const Card = ({ mainImage, title, author, summary, slug, publishedAt }) => (
  <Link to={author ? `/vlog/${slug}/` : `/services/${slug}/`} style={{ textDecoration: 'none' }}>
    <Wrapper>
      {mainImage.image && <Img fixed={mainImage.image.asset.fixed} alt={mainImage.alt} />}

      <CardText>
        <CardTitle onBlogPage={author} dangerouslySetInnerHTML={{ __html: title }} />
        {summary ? (
          <BlogPostSummary>{summary}</BlogPostSummary>
        ) : (
          <SeeMore>
            See More
            <MultiColouredBottomBorder />
          </SeeMore>
        )}
        <br />
        <br />
        {author && (
          <AuthorAndDate>
            <Author>@{author.name}</Author>
            <span>|</span>
            <DateBlogEntryPosted>{publishedAt}</DateBlogEntryPosted>
          </AuthorAndDate>
        )}
      </CardText>
    </Wrapper>
  </Link>
);

const fadeIn = keyframes`
  0% {
    opacity: 0;
    bottom: -40px;
  }

  100% {
    opacity: 1;
    bottom: 0;
  }
`;

const SeeMore = styled.p`
  display: inline-block;
  font-weight: 600;
  font-size: 1.2rem;
`;

const MultiColouredBottomBorder = styled.div`
  background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
  height: 2px;
  bottom: -1px;
  position: relative;
`;

const Wrapper = styled.div`
  background-color: #fff;
  height: 100%;
  width: 100%;
  opacity: 0;
  animation: ${fadeIn} 0.45s ease-in-out forwards;
  position: relative;

  &:hover {
    transform: translate3d(0, -10px, 0);
    transition: transform 0.2s ease-in-out;
  }

  &:not(:hover) {
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s ease-in-out;
  }
`;

const CardText = styled.div`
  font-family: proxima-nova, sans-serif;
  color: #000;
`;

const CardTitle = styled.h3`
  font-size: ${({ onBlogPage }) => (onBlogPage ? '1.125rem' : '2rem')};
  line-height: 1.444em;
  letter-spacing: 0.01em;
  font-weight: ${({ onBlogPage }) => (onBlogPage ? 'bold' : '400')};
  margin: ${({ onBlogPage }) => (onBlogPage ? '1.25em 0 0.6em' : '0.7em 0 0.1em')};
`;

const BlogPostSummary = styled.p`
  font-size: 1rem;
  font-weight: 300;
`;

const AuthorAndDate = styled.div`
  display: flex;
  justify-content: flex-start;

  p,
  span {
    font-size: 0.875rem;
    font-weight: 300;
  }

  span {
    margin: 0 0.4em;
  }
`;

const Author = styled.p`
  color: #0099cd;
  text-transform: lowercase;
`;

const DateBlogEntryPosted = styled.p``;

export default Card;
