import * as React from 'react';

function SvgTelephoneColoured(props) {
  return (
    <svg width={31} height={31} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.415 8.354h.402m14.451 7.28c0 8.085-6.549 14.634-14.634 14.634S1 23.72 1 15.634 7.549 1 15.634 1s14.634 6.549 14.634 14.634zM9.818 25.732h11.67c.22 0 .366-.147.366-.366V6.268c0-.22-.147-.366-.366-.366H9.818c-.22 0-.367.147-.367.366v19.098c0 .183.147.366.366.366zM9.45 20.5h12.403H9.45zm5.049 2.488h2.232H14.5z"
        stroke="url(#telephone-coloured_svg__paint0_linear)"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="telephone-coloured_svg__paint0_linear"
          x1={0.634}
          y1={15.634}
          x2={30.634}
          y2={15.634}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFED00" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgTelephoneColoured;
