import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  /*
  :root {
    --custom-grey: #b2b2b2;
    --custom-yellow: #F3e500;
  } */

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  html,
  body {
    width: 100%;
  }

  /* Scrollbar styling if viewport is over 1460px */
  ${
    '' /* body::-webkit-scrollbar {
    width: 0.625em;
  }
  body::-webkit-scrollbar-track {
    background-color: ${promozooBlack};
  }
  body::-webkit-scrollbar-thumb {
    background-color: ${promozooRed};
    border-radius: 5px;
  } */
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  li,
  code {
    margin: 0;
    padding: 0;
  }

  body, code {
    font-family: proxima-nova, sans-serif;
  }

  .ReactModal__Body--open {
    overflow-y: hidden !important;
    z-index: 0 !important;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }

  .ReactModal__Content--after-open {
    width: 95%;
    height: 80%;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);

    @media screen and (min-width: 600px) {
      width: 570px;
      height: 600px;
    }

    @media screen and (min-width: 900px) {
      width: 856px;
      height: 600px;
    }
  }
`;
