import * as React from 'react';

function SvgDocumentIcon(props) {
  return (
    <svg width={60} height={60} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.316 24.632h6.474M59.474 30c0 16.263-13.21 29.474-29.474 29.474C13.737 59.474.526 46.264.526 30 .526 13.737 13.736.526 30 .526 46.263.526 59.474 13.736 59.474 30zM40.158 13.421H23.053c-.58 0-1.106.21-1.474.632L16.21 19.42a2.078 2.078 0 00-.631 1.474v23.579c0 1.158.947 2.105 2.105 2.105h22.474a2.111 2.111 0 002.105-2.105V15.526a2.111 2.111 0 00-2.105-2.105zm-24.369 6.632h6.421v-6.421l-6.42 6.42zm4.527 8.526h17.263-17.263zm0 3.947h17.263-17.263zm0 3.948h17.263-17.263z"
        stroke="url(#document-icon_svg__paint0_linear)"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="document-icon_svg__paint0_linear"
          x1={0}
          y1={30}
          x2={60}
          y2={30}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFED00" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgDocumentIcon;
