import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

const ClientLogos = ({ viewportWidth }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          clientLogos: allFile(
            filter: { relativeDirectory: { regex: "/Home/ClientLogos/" } }
            sort: { fields: [relativeDirectory], order: ASC }
          ) {
            edges {
              node {
                name
                childImageSharp {
                  fixed(width: 145, quality: 60) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      `}
      render={({ clientLogos }) => (
        <Wrapper>
          {clientLogos.edges.map(({ node: clientLogo }, index) => {
            console.log(index);
            return (
              <ClientLogo
                smallerRow={index > 14}
                fixed={clientLogo.childImageSharp.fixed}
                key={clientLogo.name}
                alt={clientLogo.name.replace(/-/g, ' ')}
              />
            );
          })}
        </Wrapper>
      )}
    />
  );
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1em 1.25rem 2em 1.25rem; 

  @media screen and (min-width: 640px) {
    justify-content: space-around;
    align-items: center;
    margin: 1.75em 3em 3em 3em;
  }

  @media screen and (min-width: 1045px) {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: dense;
    margin: 1.75em 6em 3em 6em;
  }
`;

const ClientLogo = styled(Img)`
  margin: 2rem auto;
  width: 152px;
  width: 100%;
  @media screen and (min-width: 640px) {
    margin: 2em;
  }
  @media screen and (min-width: 1045px) {
    grid-column: ${({ smallerRow }) => (smallerRow ? 'span 5' : 'span 4')};
    margin: ${({ smallerRow }) => (smallerRow ? '1rem 5rem' : '2em auto')};
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(ClientLogos);
