import * as React from 'react';

function SvgRightArrow(props) {
  return (
    <svg width={14} height={23} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.9 2l9.899 9.9-9.9 9.899" stroke="#fff" strokeWidth={3} />
    </svg>
  );
}

export default SvgRightArrow;
