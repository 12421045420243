import * as React from 'react';

function SvgWhatsappIcon(props) {
  return (
    <svg width={11} height={11} viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.98801 43.037L0 58L16.24 53.7271C20.201 55.813 24.711 57 29.5 57C45.24 57 58 44.24 58 28.5C58 12.76 45.24 0 29.5 0C13.76 0 1 12.76 1 28.5C1 33.812 2.457 38.78 4.98801 43.037ZM41.514 32.6539C41.514 32.6539 46.367 35.4979 47.683 37.985C48.243 39.042 48.048 40.3409 47.202 41.189L45.444 42.947C39.867 48.5239 33.571 45.778 27.994 40.2009L22.897 35.104L17.8 30.007C12.223 24.4299 9.47702 18.134 15.054 12.5569L16.812 10.799C17.658 9.95294 18.957 9.75696 20.015 10.317C22.502 11.6329 25.346 16.486 25.346 16.486C25.972 17.584 26.042 18.908 24.926 19.535C24.926 19.535 23.035 21.1119 22.763 21.5129C21.522 23.345 21.57 25.0419 23.283 26.7549L27.264 30.736L31.245 34.717C32.958 36.4299 34.655 36.4779 36.487 35.2369C36.888 34.965 38.465 33.074 38.465 33.074C39.091 31.9579 40.416 32.028 41.514 32.6539Z" fill="white"/>
</svg>
  );
}

export default SvgWhatsappIcon;
