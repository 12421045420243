import * as React from 'react';

function SvgLinkedinIcon(props) {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={16} cy={16} r={16} fill="#C4C4C4" />
      <path
        d="M12.08 22.907H9.187v-9.334h2.893v9.334zm-1.4-10.547c-.933 0-1.68-.747-1.68-1.68C9 9.747 9.747 9 10.68 9c.933 0 1.68.747 1.68 1.68 0 .933-.747 1.68-1.68 1.68zm12.227 10.547h-2.894v-4.48c0-1.12 0-2.427-1.493-2.427s-1.773 1.213-1.773 2.427V23h-2.8v-9.333h2.8v1.306c.373-.746 1.306-1.493 2.706-1.493 2.894 0 3.454 1.96 3.454 4.387v5.04z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgLinkedinIcon;
