import React, { Component } from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
// import MapMarkerIcon from 'images/ContactUs/Map/gb-map-marker.png';

class Map extends Component {
  componentDidMount() {
    const key = 'AIzaSyDwqgCQLkceFpcm0QUI2I-QYqyl6CGJEB4';
    const googleMapsAPIModule = require('google-maps-api')(key, ['places']);
    googleMapsAPIModule()
      .then((mapObject) => {
        return new Promise((resolve) => {
          this.map = new mapObject.Map(this.mapRef, {
            zoom: 16,
            center: {
              lat: 53.33419,
              lng: -6.25678
            },
            mapTypeControl: false,
            scrollwheel: false,
            title: 'Google Map'
          });
          resolve(mapObject);
        });
      })
      .then((mapObject) => {
        new mapObject.Marker({
          position: {
            lat: 53.33419,
            lng: -6.25678
          },
          title: 'Get Broadcasting',
          map: this.map,
          icon: {
            url: 'https://i.ibb.co/QDxCNDg/gb-map-marker.png'
          }
        });
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <Wrapper
        ref={(component) => {
          this.mapRef = component;
        }}
      />
    );
  }
}

const Wrapper = styled.section`
  width: 100%;
  height: 400px;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Map);
