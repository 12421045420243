import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import Img from 'gatsby-image';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import { InView } from 'react-intersection-observer';
import editFilename from 'helpers/editFilename';
import VideoIcon from 'images/Home/ServiceAnimations/Icons/VideoIcon';
import TrainingIcon from 'images/Home/ServiceAnimations/Icons/TrainingIcon';
import StrategyIcon from 'images/Home/ServiceAnimations/Icons/StrategyIcon';
import LivestreamIcon from 'images/Home/ServiceAnimations/Icons/LivestreamIcon';

class Services extends PureComponent {
  state = {};

  handleIntersectionObserver = (inView, id) => {
    this.setState({
      [`service-${id}`]: inView
    });
  };

  render() {
    return (
      <Wrapper>
        <StaticQuery
          query={graphql`
            {
              backgroundImgs: allFile(
                filter: { relativeDirectory: { regex: "/Home/ServiceAnimations/BackgroundImgs/" } }
                sort: { fields: [relativeDirectory], order: ASC }
              ) {
                edges {
                  node {
                    name
                    childImageSharp {
                      fluid(maxWidth: 295, maxHeight: 410, quality: 60) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }
                }
              }
            }
          `}
          render={({ backgroundImgs }) => {
            const { edges } = backgroundImgs;
            return (
              <>
                <Service
                  to="/services/live-stream-events/"
                  animateWhenInViewOnMobileOrTablet={this.state[`service-livestream`]}>
                  <BackgroundImg
                    fluid={edges[3].node.childImageSharp.fluid}
                    alt={editFilename(edges[3].node.name)}
                  />
                  <GradientBackground>
                    <LivestreamIcon />
                  </GradientBackground>
                  <InView
                    as="div"
                    threshold={0.25}
                    onChange={(inView) => this.handleIntersectionObserver(inView, 'livestream')}>
                    <ServiceName>Live Stream & Events</ServiceName>
                  </InView>
                  <Button>
                    <Text>See More</Text>
                  </Button>
                </Service>
                <Service
                  to="/services/video-animation/"
                  animateWhenInViewOnMobileOrTablet={this.state[`service-video`]}>
                  <BackgroundImg
                    fluid={edges[0].node.childImageSharp.fluid}
                    alt={editFilename(edges[0].node.name)}
                  />
                  <GradientBackground>
                    <VideoIcon />
                  </GradientBackground>
                  <InView
                    as="div"
                    threshold={0.25}
                    onChange={(inView) => this.handleIntersectionObserver(inView, 'video')}>
                    <ServiceName>Video & Animation</ServiceName>
                  </InView>
                  <Button>
                    <Text>See More</Text>
                  </Button>
                </Service>
                <Service
                  to="/services/strategy-and-content/"
                  animateWhenInViewOnMobileOrTablet={this.state[`service-strategy`]}>
                  <BackgroundImg
                    fluid={edges[2].node.childImageSharp.fluid}
                    alt={editFilename(edges[2].node.name)}
                  />
                  <GradientBackground>
                    <StrategyIcon />
                  </GradientBackground>
                  <InView
                    as="div"
                    threshold={0.25}
                    onChange={(inView) => this.handleIntersectionObserver(inView, 'strategy')}>
                    <ServiceName>Content Strategy</ServiceName>
                  </InView>
                  <Button>
                    <Text>See More</Text>
                  </Button>
                </Service>
              </>
            );
          }}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.section`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;

  @media screen and (max-width: 619px) {
    align-items: center;
    flex-direction: column;
    flex-shrink: 1;
    margin: 1rem 3%;
  }

  @media screen and (min-width: 620px) {
    max-width: 100%;
    margin: 3em auto 0 auto;
  }

  @media screen and (min-width: 1180px) {
    margin: 1rem auto 0 auto;
    max-width: 1185px;
  }
`;

/* Transitioning from solid colours to gradients in React/Gatsby when using SVGs seems to be a pain in
the ass, so this is my workaround! */
const GradientBackground = styled.div`
  align-self: center;
  background: #fff;
  border-radius: 50%;
  top: 30%;
  max-height: 114px;
  max-width: 114px;
  position: absolute;
  transition: top 0.5s;
`;

const ServiceName = styled.h3`
  color: #000;
  font-size: 1.5rem;
  position: absolute;
  top: 65%;
  text-align: center;
  width: 100%;
  transition: all 0.5s ease-in-out;
`;

const Button = styled.div`
  align-items: center;
  align-self: center;
  background: linear-gradient(254.16deg, #e4097e 26.97%, #ffef00 122.99%);
  border-radius: 50% / 250%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 40px;
  justify-content: center;
  opacity: 0;
  padding: 0.75em;
  position: absolute;
  top: 64.5%;
  width: 120px;
`;

const Text = styled.p`
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
`;

const BackgroundImg = styled(Img)`
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
`;

const grow = keyframes`
  from { transform: scale3d(1, 1, 1);}
  to { transform: scale3d(1.2, 1.2, 1);}
`;

const shrink = keyframes`
  from { transform: scale3d(1.2, 1.2, 1);}
  to { transform: scale3d(1, 1, 1);}
`;

const getAnimationCSS = () => {
  return css`
    & svg path.fill {
      fill: #fff;
      transition: fill 0.5s ease-in-out;
    }
    
    & svg path {
      stroke: #fff;
      transition: stroke 0.5s ease-in-out;
    }

    ${ServiceName} {
      color: #fff;
      top: 50%;
      transition: all 0.5s ease-in-out;
    }

    ${Button} {
      opacity: 1;
      transition: opacity 0.4s 0.235s ease-in-out;

      &:hover {
        transform: scale3d(1.2, 1.2, 1);
        animation: ${grow} 0.2s ease-in-out forwards;
      }

      &:not(:hover) {
        transform: scale3d(1, 1, 1);
        animation: ${shrink} 0.2s ease-in-out forwards;
      }
    }

    ${BackgroundImg} {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }

    ${GradientBackground} {
      background: linear-gradient(
        215.31deg,
        rgba(228, 9, 126, 0.2) 22.88%,
        rgba(255, 239, 0, 0.2) 88.81%
      );
      top: 15%;
      transition: top 0.5s;
    }
  `;
};

const Service = styled(Link)`
  height: 100%;
  max-height: 410px;
  max-width: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 385px) {
    max-width: 260px;
  }

  @media screen and (min-width: 1180px) {
    max-width: 360px;
  }

  svg path {
    stroke: #000;
    transition: stroke 0.5s ease-in-out;
  }

  @media screen and (max-width: 799px) {
    ${({ animateWhenInViewOnMobileOrTablet }) =>
      animateWhenInViewOnMobileOrTablet && getAnimationCSS()}
  }

  @media screen and (min-width: 800px) {
    &:hover {
      ${getAnimationCSS()};
    }
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Services);
