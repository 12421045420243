import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';

const HomePageH1 = () => {
  return (
    <H1>
      Corporate Live Broadcast
      <br />& Video Content Specialists
    </H1>
  );
};

const H1 = styled.h1`
  font-size: 1rem;
  font-weight: 100;
  text-align: center;
  width: 90%;
  color: #FFFFFF;
  margin: 1rem 0;
  @media screen and (min-width: 640px) {
    font-size: 2.5rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 3.75rem;
    margin: 3.5rem 0;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(HomePageH1);
