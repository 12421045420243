import React from 'react';
import styled, { css } from 'styled-components';

const Button = ({
  borderRadius = '2px',
  boxShadow = '0px 4px 4px rgba(79, 79, 79, 0.2)',
  buttonColor = '--connexus-light-teal',
  buttonHoverColor = '--connexus-dark-teal',
  buttonPressedColor = '--connexus-dark-green',
  children,
  disabled,
  fontColor = '--connexus-white',
  fontHoverColor = '--connexus-white',
  fontPressedColor,
  fontFamily = 'proxima-nova, sans-serif',
  fontSize = '1rem',
  fontWeight = 'bold',
  handleClick,
  height = '40px',
  innerRef,
  letterSpacing,
  scale,
  text,
  transformOrigin = 'center center',
  type = 'button',
  width = '147px'
}) => (
  <Wrapper
    borderRadius={borderRadius}
    boxShadow={boxShadow}
    buttonColor={buttonColor}
    buttonHoverColor={buttonHoverColor}
    buttonPressedColor={buttonPressedColor}
    disabled={disabled}
    fontColor={fontColor}
    fontHoverColor={fontHoverColor}
    fontPressedColor={fontPressedColor}
    fontSize={fontSize}
    fontFamily={fontFamily}
    fontWeight={fontWeight}
    height={height}
    letterSpacing={letterSpacing}
    onClick={(e) => handleClick && handleClick(e)}
    ref={innerRef}
    scale={scale}
    transformOrigin={transformOrigin}
    type={type}
    width={width}>
    <span>
      {text}
      {children}
    </span>
  </Wrapper>
);

const Wrapper = styled.button`
  align-items: center;
  border-radius: ${({ borderRadius }) => borderRadius};
  border: none;
  box-shadow: ${({ boxShadow }) => boxShadow && boxShadow};
  cursor: pointer;
  display: flex;
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  height: ${({ height }) => height && height};
  justify-content: center;
  outline: none; /* TODO: Find more accessible way of hiding outline when button is clicked */
  width: ${({ width }) => width && width};
  position: relative;
  z-index: 2;

  * {
    pointer-events: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #bdbdbd !important;
      pointer-events: none;
    `}

  span {
    display: flex;
    align-items: center;
    font-size: ${({ fontSize }) => fontSize && fontSize};
    font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
    letter-spacing: ${({ letterSpacing }) => letterSpacing && letterSpacing};
    text-transform: uppercase;
    font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  }

  &:hover {
    background-color: ${({ buttonHoverColor }) =>
      buttonHoverColor && css`var(${buttonHoverColor})`};
    box-shadow: ${({ boxShadow }) => boxShadow && boxShadow};
    transform: ${({ scale }) => scale && css`scale3d(${scale}, ${scale}, ${scale})`};
    transition: background-color 0.125s ease-in-out, transform 0.125s ease-in-out,
      box-shadow 0.125s ease-in-out;
    transform-origin: ${({ transformOrigin }) => transformOrigin && transformOrigin};

    span {
      color: ${({ fontHoverColor }) => fontHoverColor && css`var(${fontHoverColor})`};
      transition: color 0.125s ease-in-out;
    }
  }

  &:not(:hover) {
    background-color: ${({ buttonColor }) => buttonColor && css`var(${buttonColor})`};
    box-shadow: ${({ boxShadow }) => boxShadow && boxShadow};
    transform: scale3d(1, 1, 1);
    transition: background-color 0.125s ease-in-out, transform 0.125s ease-in-out,
      box-shadow 0.125s ease-in-out;
    transform-origin: ${({ transformOrigin }) => transformOrigin && transformOrigin};

    span {
      color: ${({ fontColor }) => fontColor && css`var(${fontColor})`};
      transition: color 0.125s ease-in-out;
    }
  }

  &:active {
    box-shadow: ${({ boxShadow }) => boxShadow && boxShadow};
    background-color: ${({ buttonPressedColor }) =>
      buttonPressedColor && css`var(${buttonPressedColor})`};
    transition: background-color 0.025s ease-in-out;

    span {
      color: ${({ fontPressedColor }) => fontPressedColor && css`var(${fontPressedColor})`};
      transition: color 0.025s ease-in-out;
    }
  }
`;

export default Button;
