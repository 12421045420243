import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import defaultThumbnail from 'images/Shared/Misc/get-broadcasting-homepage-screenshot.jpg';

export default function SEO({
  lang,
  meta,
  pageSpecificTitle,
  pageSpecificSiteTitle,
  pageSpecificDescription,
  pageSpecificThumbnail,
  pageSpecificThumbnailAlt,
  pathname
}) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              defaultSiteTitle
              defaultDescription
              defaultThumbnailAlt
              siteUrl
              getBroadcastingTwitterPage
              author
            }
          }
        }
      `}
      render={(data) => {
        const {
          defaultSiteTitle,
          defaultDescription,
          defaultThumbnailAlt,
          siteUrl,
          getBroadcastingTwitterPage,
          author
        } = data.site.siteMetadata;
        const siteTitle = pageSpecificSiteTitle || defaultSiteTitle;
        const description = pageSpecificDescription || defaultDescription;
        const thumbnail = pageSpecificThumbnail?.includes('cdn.sanity.io')
          ? pageSpecificThumbnail
          : pageSpecificThumbnail
          ? `${siteUrl}${pageSpecificThumbnail}`
          : `${siteUrl}${defaultThumbnail}`;
        const thumbnailAlt = pageSpecificThumbnailAlt || defaultThumbnailAlt;
        const url = `${siteUrl}${pathname}`;
        console.log('thumbnail', thumbnail);
        console.log('url', url);
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={pageSpecificTitle}
            titleTemplate={`%s | ${siteTitle}`}
            link={[
              {
                rel: 'canonical',
                href: url
              }
            ]}
            meta={[
              {
                property: 'og:site_name',
                content: defaultSiteTitle
              },
              {
                name: `description`,
                content: description
              },
              {
                property: `og:locale`,
                content: 'en_GB'
              },
              {
                property: `og:url`,
                content: url
              },
              {
                property: `og:title`,
                content: `${pageSpecificTitle} | ${defaultSiteTitle}`
              },
              {
                property: `og:description`,
                content: description
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                property: `og:image`,
                content: thumbnail
              },
              {
                property: `og:image:url`,
                content: thumbnail
              },
              {
                property: `og:image:secure_url`,
                content: thumbnail
              },
              {
                property: `og:image:alt`,
                content: thumbnailAlt
              },
              {
                property: 'og:image:width',
                content: '1200'
              },
              {
                property: 'og:image:height',
                content: '630'
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`
              },
              {
                name: `twitter:image`,
                content: thumbnail
              },
              {
                property: 'twitter:image:alt',
                content: thumbnailAlt
              },
              {
                name: `twitter:site`,
                content: getBroadcastingTwitterPage
              },
              {
                name: `twitter:creator`,
                content: getBroadcastingTwitterPage
              },
              {
                name: `twitter:title`,
                content: `${pageSpecificTitle} | ${defaultSiteTitle}`
              },
              {
                name: `twitter:description`,
                content: description
              },
              {
                name: `author`,
                content: author
              }
            ].concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: []
};
