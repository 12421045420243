import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ScrollToTopHigherOrderComponent from 'react-scroll-up';
import GetBroadcastingLogo from 'images/Shared/Footer/GetBroadcastingLogoGrey';
// import YouTubeIcon from 'images/Shared/Footer/YoutubeIcon';
import VimeoIcon from 'images/Shared/Footer/VimeoIcon';
import LinkedInIcon from 'images/Shared/Footer/LinkedinIcon';
import FacebookIcon from 'images/Shared/Footer/FbIcon';
import InstaIcon from 'images/Shared/Footer/InstaIcon';
import GreyTelephoneIcon from 'images/Shared/Footer/GreyTelephone';
import GreyEnvelopeIcon from 'images/Shared/Footer/GreyEnvelope';
import GreyMapMarkerIcon from 'images/Shared/Footer/GreyMapMarker';
import ScrollToTopSVG from 'images/Shared/Footer/scroll_to_top.svg';

export default () => (
  <>
    <HRWithGradient />
    <Footer>
      <LogoAndSocialLinks>
        <StyledGetBroadcastingLogo />
        <SocialLinks>
          <a href="https://vimeo.com/showcase/getbroadcasting" target="__blank">
            <VimeoIcon />
          </a>
          <a href="https://www.linkedin.com/company/get-broadcasting/" target="__blank">
            <LinkedInIcon />
          </a>
          <a href="https://www.facebook.com/GetBroadcastingStudios" target="__blank">
            <FacebookIcon />
          </a>
          <a href="https://www.instagram.com/get_broadcasting/" target="__blank">
            <InstaIcon />
          </a>
        </SocialLinks>
      </LogoAndSocialLinks>
      <SmallPrint>
        <List>
          Contact Us
          <ListItem>
            <span>
              <GreyTelephoneIcon />
            </span>
            +353 1 675 5050
          </ListItem>
          {/* <ListItem>
            <span>
              <GreyEnvelopeIcon />
            </span>
            hello@getbroadcasting.com
          </ListItem> */}
          <ListItem>
            <span>
              <GreyMapMarkerIcon />
            </span>
            12 Hatch Street Lower, Dublin 2, D02 R682
          </ListItem>
        </List>
        <List>
          Main Menu
          <ListItem>
            <Link to="/">Home</Link>
          </ListItem>
          <ListItem>
            <Link to="/portfolio/">Portfolio</Link>
          </ListItem>
          <ListItem>
            <Link to="/vlog/">Vlog Corner</Link>
          </ListItem>
          <ListItem>
            <Link to="/about-us/">About</Link>
          </ListItem>
          <ListItem>
            <Link to="/contact-us/">Contact</Link>
          </ListItem>
        </List>
        <List>
          Terms
          <ListItem>
            <a href="/privacy-policy/" target="__blank">
              Privacy Policy
            </a>
          </ListItem>
          <ListItem>
            <a href="/cookie-policy/" target="__blank">
              Cookie Policy
            </a>
          </ListItem>
        </List>
      </SmallPrint>
      <ScrollToTopHigherOrderComponent
        style={{
          cursor: 'pointer',
          transitionDuration: '0.2s',
          transitionTimingFunction: 'linear',
          transitionDelay: '0s',
          position: 'absolute',
          top: '-24px',
          right: '24px'
        }}
        easing="easeInOutQuint"
        duration={1100}
        showUnder={0}>
        <ScrollToTopIcon>
          <img src={ScrollToTopSVG} alt="" />
        </ScrollToTopIcon>
      </ScrollToTopHigherOrderComponent>
      <div style={{position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '1rem',
    fontSize: '.75rem',
    background: '#c4c4c4',
    color: '#333'}}>Website developed by: <a style={{textDecoration: 'none', color: '#333' }} href="https://www.agencyx.ie" target="blank">Agency X</a></div>
    </Footer>
  </>
);

const HRWithGradient = styled.div`
  background: linear-gradient(262.46deg, #e4097e 26.97%, #ffef00 122.99%);
  height: 1px;
  width: 100%;
`;

const Footer = styled.footer`
  align-items: center;
  background: rgba(229, 229, 229, 0.5);
  display: flex;
  flex-direction: column;
  padding: 1.563em;
  position: relative;
  padding-bottom:5rem;

  @media screen and (min-width: 900px) {
    flex-direction: row;
  }
`;

const LogoAndSocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 214px;
`;

const StyledGetBroadcastingLogo = styled(GetBroadcastingLogo)`
  align-self: center;

  @media screen and (min-width: 900px) {
    align-self: flex-start;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.625em;
`;

const List = styled.ul`
  color: #0095cd;
  font-size: 1.125rem;
  line-height: 1.375em;
  list-style: none;

  &:first-of-type li span {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin-right: 0.55em;
    position: relative;
    top: 1px;
    width: 13px;

    @media screen and (min-width: 900px) {
      margin-right: 0.95em;
    }
  }

  a {
    color: #c4c4c4;
  }

  @media screen and (max-width: 899px) {
    margin-top: 1.3em;
  }
`;

const ListItem = styled.li`
  color: #c4c4c4;
  font-size: 0.875rem;
  line-height: 2em;

  &:first-of-type {
    padding-top: 0.4em;
  }

  @media screen and (min-width: 900px) {
    &:first-of-type {
      padding-top: 1em;
    }
  }
`;

const SmallPrint = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  @media screen and (max-width: 899px) {
    align-items: center;
    flex-direction: column;

    ${List} {
      text-align: center;
    }
  }
`;

const ScrollToTopIcon = styled.div`
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  &:not(:hover) {
    transform: scale3d(1, 1, 1);
  }
`;
