import React, { forwardRef, useState, useEffect } from "react";
import styled, { keyframes } from 'styled-components';
import { DialogOverlay, DialogContent } from "@reach/dialog";
import '@reach/dialog/styles.css';

const useDelayUnmount = (isMounted, delayTime) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
}

const Dialog = forwardRef((props, ref) => {
  const { isVisible, children, onDismiss, large } = props;
  const shouldRenderChild = useDelayUnmount(isVisible, 300);

  return (
    (isVisible || (!isVisible && shouldRenderChild)) && (
      <StyledDialogOverlay
        ref={ref}
        onDismiss={onDismiss}
        large={large}>
        <StyledDialogContent>
          {children}
        </StyledDialogContent>
      </StyledDialogOverlay>
    )
  );

});


const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;;
  }

  100% {
    opacity: 0;;
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  0% {
    transform: translate(0);
  }

  100% {
    transform: translateY(10%);
  }
`;


const StyledDialogOverlay = styled(DialogOverlay)`
  align-items: ${({ large }) => large ? 'flex-start' : 'center' };
  backdrop-filter: blur(0.125rem);
  background-color: rgb(0 0 0 / 0.25);
  display: flex;
  justify-content: center;
  overflow-x: hidden !important;
  padding: 0.75rem;
  z-index: 12;

  &[data-state='open'] {
    animation: ${fadeIn} 300ms ease-in-out
      forwards;
  }

  &[data-state='close'],
  &[data-state='dismiss'] {
    animation: ${fadeOut} 300ms ease-in-out
      forwards;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: transparent !important;
  box-sizing: border-box;
  padding: 0 !important;
  width: max-content !important;

  &[data-state='open'] {
    animation: ${slideUp} 300ms ease-in-out
      forwards;
  }

  &[data-state='dismiss'] {
    animation: ${slideDown} 300ms ease-in-out
      forwards;
  }
`;

export default Dialog;