import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import { motion, AnimatePresence } from 'framer-motion';
import { withPrefix } from 'gatsby';
import styled from 'styled-components';
import { HomePageH1 } from 'components';
import FilePlayer from 'react-player/lib/players/FilePlayer';

class ResponsiveVideo extends PureComponent {
  state = {
    componentIsReady: false,
    muted: true
  };

  componentDidMount() {
    this.setState({
      componentIsReady: true,
      muted: true
    });
  }

  render() {
    const { viewportWidth, videoTitle, handleSplashScreen, pathname, hideControls } = this.props;
    const { componentIsReady, muted } = this.state;

    return (
      componentIsReady && (
        <Wrapper>
          <AnimatePresence exitBeforeEnter>
            <StyledFilePlayer
              controls={!hideControls}
              height="100%"
              onReady={handleSplashScreen}
              loop
              muted={pathname === '/' ? muted : false}
              volume={1}
              playing
              playsinline
              url={[
                {
                  src: withPrefix(
                    `videos/full_length/${videoTitle}${
                      viewportWidth <= 426
                        ? '_1'
                        : viewportWidth <= 640
                        ? '_2'
                        : viewportWidth <= 960
                        ? '_3'
                        : '_4'
                    }.mp4`
                  ),
                  type: 'video/mp4'
                }
              ]}
              width="100%"
            />
          </AnimatePresence>
          {pathname === '/' && (
            <>
              <VideoOverlay>
                <HomePageH1 />
              </VideoOverlay>
              <SoundControl
                onClick={() =>
                  this.setState((prevState) => {
                    if (!prevState.muted) {
                      return {
                        muted: true
                      };
                    } else {
                      return {
                        muted: false
                      };
                    }
                  })
                }>
                {this.state.muted ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M533.6 32.5C598.5 85.3 640 165.8 640 256s-41.5 170.8-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z" />
                  </svg>
                )}
              </SoundControl>
            </>
          )}
        </Wrapper>
      )
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const VideoOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const StyledFilePlayer = styled(FilePlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const SoundControl = styled(motion.div).attrs({
  whileHover: {
    scale: 1.15
  },
  whileTap: {
    scale: 0.85
  }
})`
  align-items: center;
  background: #e4097e;
  border: none;
  border-radius: 50%;
  bottom: 1rem;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  opacity: 0.7;
  padding: 0.625rem;
  position: absolute;
  right: 1rem;
  width: 40px;
  svg {
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: 800px) {
    bottom: 2rem;
    height: 50px;
    right: 2rem;
    width: 50px;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(ResponsiveVideo);
