import * as React from 'react';

function SvgLivestreamIcon(props) {
  return (
    <svg
      width={114}
      height={114}
      viewBox="0 0 197 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M98.5 196C152.348 196 196 152.348 196 98.5C196 44.6522 152.348 1 98.5 1C44.6522 1 1 44.6522 1 98.5C1 152.348 44.6522 196 98.5 196Z"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M138.81 78.8575H58.8101V127.338H138.81V78.8575Z" className="fill" fill="#000" />
      <path
        d="M155.78 137.037H153.36V71.5775C153.36 67.5675 150.1 64.3075 146.09 64.3075H51.54C47.53 64.3075 44.27 67.5675 44.27 71.5775V137.028H41.85C39.18 137.028 37 139.207 37 141.877V149.147C37 151.817 39.18 153.998 41.85 153.998H155.79C158.46 153.998 160.64 151.817 160.64 149.147V141.877C160.64 139.207 158.47 137.028 155.79 137.028M53.96 76.4275C53.96 75.0875 55.05 74.0075 56.38 74.0075H141.23C142.57 74.0075 143.65 75.0975 143.65 76.4275V129.757C143.65 131.097 142.56 132.177 141.23 132.177H56.39C55.05 132.177 53.97 131.087 53.97 129.757V76.4275H53.96ZM155.78 149.157H41.84V141.888H86.9L88.61 143.597C89.06 144.057 89.68 144.308 90.32 144.308H107.29C107.93 144.308 108.55 144.057 109 143.597L110.71 141.888H155.77V149.157H155.78Z"
        className="fill"
        fill="#000"
      />
      <path
        d="M73.98 34.2675C72.84 35.4075 72.84 37.2675 73.98 38.4075C75.12 39.5475 76.97 39.5475 78.12 38.4075C89.53 26.9975 108.09 26.9975 119.5 38.4075C120.64 39.5475 122.5 39.5475 123.64 38.4075C124.78 37.2675 124.78 35.4075 123.64 34.2675C109.95 20.5775 87.68 20.5775 73.99 34.2675"
        className="fill"
        fill="#000"
      />
      <path
        d="M82.26 42.5475C81.12 43.6875 81.12 45.5475 82.26 46.6875C83.4 47.8275 85.26 47.8275 86.4 46.6875C93.25 39.8375 104.38 39.8375 111.23 46.6875C112.37 47.8275 114.23 47.8275 115.37 46.6875C116.51 45.5475 116.51 43.6875 115.37 42.5475C106.24 33.4175 91.39 33.4175 82.27 42.5475"
        className="fill"
        fill="#000"
      />
      <path
        d="M90.53 50.8276C89.39 51.9676 89.39 53.8276 90.53 54.9676C91.67 56.1076 93.52 56.1076 94.67 54.9676C96.95 52.6876 100.66 52.6876 102.95 54.9676C104.09 56.1076 105.95 56.1076 107.09 54.9676C108.23 53.8276 108.23 51.9676 107.09 50.8276C102.52 46.2576 95.1001 46.2676 90.5401 50.8276"
        className="fill"
        fill="#000"
      />
    </svg>
  );
}

export default SvgLivestreamIcon;
