import * as React from 'react';

function SvgLinkedinIcon(props) {
  return (
    <svg width={10} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.721 9.68V3.561H.885v6.12h1.836zm0-8.304c0-.547-.36-.947-.972-.947-.54 0-.936.4-.936.947 0 .546.36.947.936.947.612.036.972-.401.972-.947zm3.06 8.304V6.184c0-.183 0-.365.072-.51.144-.365.468-.765 1.044-.765.756 0 1.188.437 1.188 1.275V9.68h1.871V6.11c0-1.93-1.008-2.804-2.34-2.804-1.115 0-1.583.62-1.871 1.056v-.801H3.909c.036.583 0 6.156 0 6.156h1.872V9.68z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgLinkedinIcon;
