import * as React from 'react';

function SvgChevronLeft(props) {
  return (
    <svg
      width={14}
      height={23}
      viewBox="0 0 14 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M12.1006 21.1602L2.20109 11.2607L12.1006 1.36117" stroke="white" stroke-width="3" />
    </svg>
  );
}

export default SvgChevronLeft;
