import React from 'react';
import sizes from 'react-sizes';
import { Link } from 'gatsby';
import styled from 'styled-components';
import RightArrow from 'images/Shared/Misc/RightArrow';

const SeeOurPortfolio = ({ viewportWidth }) => {
  return (
    <Wrapper>
      <Link to="/portfolio/" style={{ textDecoration: 'none' }}>
        <Button>
          <Text>See Our Portfolio</Text>
          <RightArrow style={{ marginRight: '0.25em' }} />
        </Button>
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8.45em;
`;

const Button = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(254.16deg, #e4097e 26.97%, #ffef00 122.99%);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  padding: 0.75em;
  border-radius: 50% / 250%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const Text = styled.p`
  color: #fff;
  font-size: 1.125rem;
  padding: 0 1em;
  margin: 0;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(SeeOurPortfolio);
