import * as React from 'react';

function SvgTickIcon(props) {
  return (
    <svg width={60} height={60} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M59.474 30c0 16.263-13.21 29.474-29.474 29.474C13.737 59.474.526 46.264.526 30 .526 13.737 13.736.526 30 .526 46.263.526 59.474 13.736 59.474 30zM31.368 43.21l-14.21-14.157 14.21 14.158zm3.685-3.684L27.79 32.21l7.263 7.316zm3.736-3.737l-7.21-7.21 7.21 7.21zm3.737-3.736l-7.263-7.263 7.263 7.263zm3.737-3.737l-7.316-7.263 7.316 7.263zM31.526 43.42l17.106-17.105a2.097 2.097 0 000-3l-4.474-4.474a2.097 2.097 0 00-3 0L27.789 32.211l-5.579-5.58a2.097 2.097 0 00-3 0l-4.473 4.474a2.097 2.097 0 000 3L24 43.368c.474.474 1 .843 1.579 1.106 2.158.947 4.421.42 5.947-1.053z"
        stroke="url(#tick-icon_svg__paint0_linear)"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="tick-icon_svg__paint0_linear"
          x1={0}
          y1={30}
          x2={60}
          y2={30}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFED00" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgTickIcon;
