import React from 'react';
import { Card } from 'components';
import styled from 'styled-components';
import { StaticQuery } from 'gatsby';
import sizes from 'react-sizes';
import Img from 'gatsby-image';

const getInfoBoxesAndMap = (map) => (
  <>
    <InfoBox>
      <p>
        <b>Course Times:</b>
        <br />
        <span>Part 1</span>10:00 - 13:00
        <br />
        <span>Lunch </span>13:00 - 14:00
        <br />
        <span>Part 2</span>14:00 - 16:00
        <br />
        <br />
        Options are available for a condensed 5-hour session from 8:30 – 13:30.
        <br />
        <br />
        <b>Location:</b>
        <br />
        Our Course is held at our Studios on South William Street, Dublin 2.
      </p>
    </InfoBox>
    <Map key={map.name} fluid={map.childImageSharp.fluid} />
    <InfoBox>
      <p>
        Light lunch and tea/coffee is included. 
        <br />
        <br />
        <b>Contact and enquiries</b>
        <br />
        For group bookings, calendar availability and further queries, please get in touch: <a href="mailto:contact@getbroadcasting.com" target="_blank">contact@getbroadcasting.com</a>
      </p>
    </InfoBox>
  </>
);

const ContentAside = ({ otherServices, recentBlogPosts, slug, viewportWidth }) => {
  const cards = otherServices || recentBlogPosts;
  return (
    <Wrapper>
      {!slug.includes('training') && (
        <p>
          {slug === 'about-us' ? 'Our Services' : otherServices ? 'Other Services' : 'Recent Posts'}
        </p>
      )}
      {!slug.includes('training') && (
        <CardWrapper onBlogPage={recentBlogPosts} slug={slug}>
          {cards &&
            cards.map((card) => {
              return (
                <Card
                  mainImage={card.mainImage}
                  title={card.title}
                  author={card.author}
                  summary={card.summary}
                  slug={card.slug?.current}
                  publishedAt={card.publishedAt}
                  key={card.id}
                />
              );
            })}
        </CardWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin: 0 auto 4rem;

  @media screen and (min-width: 870px) {
    margin: unset;
  }

  @media screen and (min-width: 1200px) {
    margin: 3.55rem 0 4rem;
    max-width: 325px;
  }

  > p:first-of-type {
    margin: 2.5em auto 1.25em;
    text-align: center;
    font-size: 1.5rem;
    font-family: proxima-nova, sans-serif;
    font-weight: 300;
    color: #000;

    @media screen and (min-width: 1200px) {
      margin: 3.55rem auto 1.25em;
      text-align: left;
    }
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > a {
    display: inline-block;
    max-width: 325px;
  }

  > a {
    margin-bottom: 2em;
  }

  @media screen and (min-width: 870px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: ${({ onBlogPage, slug }) =>
      onBlogPage ? '600px' : slug === 'about-us' ? '100%' : '890px'};
    margin: 0 auto;

    > a:first-of-type {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 1200px) {
    flex-direction: column;
    justify-content: unset;

    > a:first-of-type {
      margin-bottom: 3em;
    }

    .gatsby-image-wrapper {
      max-width: 360px;
      height: 198px;
    }
  }
`;

const ImageCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  .gatsby-image-wrapper {
      width: 100%;
      height: 325px;
    }

  @media screen and (min-width: 870px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: '890px';
    margin: 0 auto;
  }

  @media screen and (min-width: 1200px) {
    flex-direction: column;
    justify-content: unset;
    gap: 2rem;
    .gatsby-image-wrapper {
      max-width: 325px;
      height: 325px;
    }
  }
`;

const AsideImage = styled(Img)`
  width: 100%;
  &:nth-child(2) {
    margin-bottom: 3rem;
  }
`;

const InfoBoxAndMapWrapperWhenOnMobileOrTablet = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 610px;
  margin: 0 auto;

  @media screen and (min-width: 815px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const InfoBox = styled.div`
  background-color: rgba(229, 229, 229, 1);
  line-height: 1.625em;
  padding: 1.1em;

  p {
    color: #000;
    font-size: 1.05rem;
    font-weight: 300;

    b {
      font-weight: 600;
    }
    
    a {
      color: #000;
    }
  }

  span {
    display: inline-block;
    width: 70px;
  }

  @media screen and (max-width: 814px) {
    &:last-of-type {
      padding-top: 0;
    }
  }

  @media screen and (min-width: 815px) {
    width: 50%;
  }

  @media screen and (min-width: 904px) {
    width: 100%;
  }
`;

const Map = styled(Img)`
  @media screen and (max-width: 903px) {
    order: 3;
    margin-bottom: 57px;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(ContentAside);
