import * as React from 'react';

function SvgShowAllCases(props) {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        cx={16}
        cy={16}
        r={15.5}
        transform="rotate(90 16 16)"
        stroke="url(#show-all-cases_svg__paint0_linear)"
      />
      <circle
        cx={9}
        cy={16}
        transform="rotate(90 9 16)"
        fill="url(#show-all-cases_svg__paint1_linear)"
        r={1}
      />
      <circle
        cx={16}
        cy={16}
        transform="rotate(90 16 16)"
        fill="url(#show-all-cases_svg__paint2_linear)"
        r={1}
      />
      <circle
        cx={23}
        cy={16}
        transform="rotate(90 23 16)"
        fill="url(#show-all-cases_svg__paint3_linear)"
        r={1}
      />
      <circle
        cx={9}
        cy={11}
        transform="rotate(90 9 11)"
        fill="url(#show-all-cases_svg__paint4_linear)"
        r={1}
      />
      <circle
        cx={16}
        cy={11}
        transform="rotate(90 16 11)"
        fill="url(#show-all-cases_svg__paint5_linear)"
        r={1}
      />
      <circle
        cx={23}
        cy={11}
        transform="rotate(90 23 11)"
        fill="url(#show-all-cases_svg__paint6_linear)"
        r={1}
      />
      <circle
        cx={9}
        cy={21}
        transform="rotate(90 9 21)"
        fill="url(#show-all-cases_svg__paint7_linear)"
        r={1}
      />
      <circle
        cx={16}
        cy={21}
        transform="rotate(90 16 21)"
        fill="url(#show-all-cases_svg__paint8_linear)"
        r={1}
      />
      <circle
        cx={23}
        cy={21}
        transform="rotate(90 23 21)"
        fill="url(#show-all-cases_svg__paint9_linear)"
        r={1}
      />
      <defs>
        <linearGradient
          id="show-all-cases_svg__paint0_linear"
          x1={16}
          y1={0}
          x2={16}
          y2={32}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4097E" />
          <stop offset={1} stopColor="#F4DC00" />
        </linearGradient>
        <linearGradient
          id="show-all-cases_svg__paint1_linear"
          x1={10}
          y1={14.625}
          x2={7.313}
          y2={18.344}
          gradientUnits="userSpaceOnUse">
          <stop offset={0.234} stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFEF00" />
        </linearGradient>
        <linearGradient
          id="show-all-cases_svg__paint2_linear"
          x1={17}
          y1={14.625}
          x2={14.313}
          y2={18.344}
          gradientUnits="userSpaceOnUse">
          <stop offset={0.234} stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFEF00" />
        </linearGradient>
        <linearGradient
          id="show-all-cases_svg__paint3_linear"
          x1={24}
          y1={14.625}
          x2={21.313}
          y2={18.344}
          gradientUnits="userSpaceOnUse">
          <stop offset={0.234} stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFEF00" />
        </linearGradient>
        <linearGradient
          id="show-all-cases_svg__paint4_linear"
          x1={10}
          y1={9.625}
          x2={7.313}
          y2={13.344}
          gradientUnits="userSpaceOnUse">
          <stop offset={0.234} stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFEF00" />
        </linearGradient>
        <linearGradient
          id="show-all-cases_svg__paint5_linear"
          x1={17}
          y1={9.625}
          x2={14.313}
          y2={13.344}
          gradientUnits="userSpaceOnUse">
          <stop offset={0.234} stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFEF00" />
        </linearGradient>
        <linearGradient
          id="show-all-cases_svg__paint6_linear"
          x1={24}
          y1={9.625}
          x2={21.313}
          y2={13.344}
          gradientUnits="userSpaceOnUse">
          <stop offset={0.234} stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFEF00" />
        </linearGradient>
        <linearGradient
          id="show-all-cases_svg__paint7_linear"
          x1={10}
          y1={19.625}
          x2={7.313}
          y2={23.344}
          gradientUnits="userSpaceOnUse">
          <stop offset={0.234} stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFEF00" />
        </linearGradient>
        <linearGradient
          id="show-all-cases_svg__paint8_linear"
          x1={17}
          y1={19.625}
          x2={14.313}
          y2={23.344}
          gradientUnits="userSpaceOnUse">
          <stop offset={0.234} stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFEF00" />
        </linearGradient>
        <linearGradient
          id="show-all-cases_svg__paint9_linear"
          x1={24}
          y1={19.625}
          x2={21.313}
          y2={23.344}
          gradientUnits="userSpaceOnUse">
          <stop offset={0.234} stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFEF00" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgShowAllCases;
