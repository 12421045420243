import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql, Link } from 'gatsby';
import editFilename from 'helpers/editFilename';
import styled, { css } from 'styled-components';
import RightArrow from 'images/Shared/Misc/RightArrow';

export default () => (
  <section>
    <StaticQuery
      query={graphql`
        {
          serviceImgs: allFile(
            filter: { relativeDirectory: { regex: "/Services/" } }
            sort: { fields: [relativeDirectory], order: ASC }
          ) {
            edges {
              node {
                id
                name
                childImageSharp {
                  fluid(maxWidth: 720, maxHeight: 680) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      `}
      render={({ serviceImgs }) => (
        <>
          <ImgAndTextWrapper>
            <Img
              fluid={serviceImgs.edges[3].node.childImageSharp.fluid}
              alt={editFilename(serviceImgs.edges[3].node.name)}
              imgStyle={{
                objectPosition: 'center right'
              }}
            />
            <Text>
              <H2>Live Stream</H2>
              <Info>
                As live stream experts with more than 15 years experience, we have you covered with
                our end-to-end service.
                <br />
                <br />
                From planning, site visits, connection testing, multi-camera setup and operation,
                vision and sound mixing and video live streaming tech and setup - we take the pain
                and worry away when it comes to broadcasting your brand.
              </Info>
              <Link to="/services/live-stream-events/" style={{ textDecoration: 'none' }}>
                <Button>
                  <ButtonText>Learn More Here</ButtonText>
                  <StyledRightArrow />
                </Button>
              </Link>
            </Text>
          </ImgAndTextWrapper>
          <ImgAndTextWrapper flip>
            <Img
              fluid={serviceImgs.edges[0].node.childImageSharp.fluid}
              alt={editFilename(serviceImgs.edges[0].node.name)}
            />
            <Text>
              <H2>Video & Animation</H2>
              <Info>
                With more than 30 years of experience in video production we have planned,
                developed, produced and edited projects across all industries.
                <br />
                <br />
                All of our productions are bespoke, as we adjust the tone, style, and budget of the
                video to suit your needs.
              </Info>
              <Link to="/services/video-animation/" style={{ textDecoration: 'none' }}>
                <Button>
                  <ButtonText>Learn More Here</ButtonText>
                  <StyledRightArrow />
                </Button>
              </Link>
            </Text>
          </ImgAndTextWrapper>
          <ImgAndTextWrapper>
            <Img
              fluid={serviceImgs.edges[2].node.childImageSharp.fluid}
              alt={editFilename(serviceImgs.edges[2].node.name)}
            />
            <Text>
              <H2>Content Strategy</H2>
              <Info>
                We don’t just make videos and neither should you. We tell stories and with these
                stories comes the strategy.
                <br />
                <br />
                With our strategy services we will work with you and perform a complete content
                audit. From this audit we will then build a structure and plot out a content
                calendar with a clear goal and target to engage your staff and your customers.
              </Info>
              <Link to="/services/strategy-and-content/" style={{ textDecoration: 'none' }}>
                <Button>
                  <ButtonText>Learn More Here</ButtonText>
                  <StyledRightArrow />
                </Button>
              </Link>
            </Text>
          </ImgAndTextWrapper>
        </>
      )}
    />
  </section>
);

const ImgAndTextWrapper = styled.section`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    flex-direction: row;

    ${({ flip }) =>
      flip &&
      css`
        flex-direction: row-reverse;
      `};

    > div,
    > section {
      width: 50%;
    }
  }
`;

const Text = styled.section`
  padding: 6.25% 8.5% 9%;
  text-align: center;

  @media screen and (min-width: 590px) {
    text-align: left;
  }

  h2,
  p {
    font-family: proxima-nova, sans-serif;
  }
`;

const H2 = styled.h2`
  font-size: calc(2.2rem + 1.1vw);
  font-weight: 300;
  margin-bottom: 0.225em;

  @media screen and (min-width: 1200px) {
    font-size: 3rem;
  }
`;

const Info = styled.p`
  font-size: calc(1rem + 0.55vw);
  font-weight: 300;
  line-height: 1.35em;

  @media screen and (min-width: 1200px) {
    font-size: 1.4rem;
  }
`;

const Button = styled.div`
  align-items: center;
  background: linear-gradient(254.16deg, #e4097e 26.97%, #ffef00 122.99%);
  border-radius: 50% / 250%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 48px;
  justify-content: space-between;
  margin: 2.2em auto 0;
  padding: 0.75em 1.125em;
  width: calc(12em + 4vw);

  @media screen and (min-width: 590px) {
    margin: 2.2em 0 0;
  }

  @media screen and (min-width: 900px) {
    width: 225px;
  }
`;

const ButtonText = styled.p`
  color: #fff;
  font-size: calc(0.875rem + 0.4vw);
  font-weight: 600;
  padding: 0 1em;

  @media screen and (min-width: 900px) {
    font-size: 1.125rem;
  }
`;

const StyledRightArrow = styled(RightArrow)`
  bottom: 1px;
  position: relative;

  path {
    strokeWidth: 2.5;
  }
`;
