import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

const InstagramLinks = [
  'https://www.instagram.com/p/ByppnVOixSA/',
  'https://www.instagram.com/p/Bw7Z3Xnnnsa/',
  'https://www.instagram.com/p/Bm0XIKhH8dZ/',
  'https://www.instagram.com/p/BkKv04phfYh/',
  'https://www.instagram.com/p/BjfFY92B1gg/',
  'https://www.instagram.com/p/Bi1_L5QA3wU/',
  'https://www.instagram.com/p/Bj7BobYBITt/',
  'https://www.instagram.com/p/Bc3AaivlJYC/',
  'https://www.instagram.com/p/BgyKkZtBovM/',
  'https://www.instagram.com/p/BbJ_zknlugy/',
  'https://www.instagram.com/p/BbJQjcklPy-/',
  'https://www.instagram.com/p/Bhv9SZygNjV/',
  'https://www.instagram.com/p/Bfx6b8lF4In/',
  'https://www.instagram.com/p/BcFCB6zlmRH/',
  'https://www.instagram.com/p/Ba81s5BlTFg/'
];

class InstagramGallery extends PureComponent {
  state = {
    showAllImgs: false
  };

  removeFileNamePrefix = (fileName) =>
    fileName
      .replace(/[0-9]*-/, '')
      .split('-')
      .join(' ');

  handleLoadMoreButton = () => this.setState({ showAllImgs: true });

  render() {
    const { showAllImgs } = this.state;
    return (
      <Wrapper>
        <InstagramImgs showAllImgs={showAllImgs}>
          <StaticQuery
            query={graphql`
              {
                allInstagramImgs: allFile(
                  filter: { relativeDirectory: { regex: "/ContactUs/InstagramImgs/" } }
                  sort: { fields: [name], order: ASC }
                ) {
                  edges {
                    node {
                      id
                      name
                      childImageSharp {
                        fluid(maxWidth: 377, maxHeight: 377, quality: 60) {
                          ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={({ allInstagramImgs }) => {
              const firstNineImgs = allInstagramImgs.edges.slice(0, 9);
              const restOfImgs = allInstagramImgs.edges.slice(9);
              return (
                <>
                  {firstNineImgs.map(({ node }, i) => (
                    <a key={node.id} target="__blank" href={InstagramLinks[i]}>
                      <InstagramImg
                        fluid={node.childImageSharp.fluid}
                        alt={this.removeFileNamePrefix(node.name)}
                      />
                    </a>
                  ))}
                  {showAllImgs &&
                    restOfImgs.map(({ node }, i) => (
                      <a key={node.id} target="__blank" href={InstagramLinks[i + 9]}>
                        <InstagramImg
                          fluid={node.childImageSharp.fluid}
                          alt={this.removeFileNamePrefix(node.name)}
                        />
                      </a>
                    ))}
                </>
              );
            }}
          />
        </InstagramImgs>
        {!showAllImgs && (
          <LoadMorebutton onClick={this.handleLoadMoreButton}>Load More</LoadMorebutton>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 4.563em auto 0;
  max-width: 1100px;
`;

const InstagramImgs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  ${({ showAllImgs }) =>
    showAllImgs &&
    css`
      margin-bottom: 5.3em;
    `};

  a {
    margin-bottom: 26px;
    max-height: 377px;
    max-width: 377px;
    width: 100%;

    @media screen and (min-width: 570px) {
      margin: 0 1% 2%;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 46%;
    }

    @media screen and (min-width: 820px) {
      max-width: 31%;
    }
  }

  @media screen and (min-width: 570px) {
    flex-direction: row;
  }
`;

const InstagramImg = styled(Img)`
  width: 100%;
`;

const LoadMorebutton = styled.div`
  align-items: center;
  align-self: flex-end;
  background: linear-gradient(262.46deg, #e4097e 26.97%, #ffef00 122.99%);
  border-radius: 1.5em;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  margin: 0.6em auto 5.3em;
  padding: 0.5em 1em;
  width: 125px;

  @media screen and (min-width: 820px) {
    margin: 0.6em 0 5.3em;
    position: relative;
    right: 1.5%;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(InstagramGallery);
