import * as React from 'react';

function SvgEnvelopeColoured(props) {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.85 9.363L16 17.575l9-8.212m-6.188 5.625L25 22.638m-11.963-7.65l-6.187 7.65M31 16c0 8.288-6.712 15-15 15-8.287 0-15-6.712-15-15C1 7.713 7.713 1 16 1c8.288 0 15 6.713 15 15zM6.7 9.512v12.976c0 .224.15.375.375.375h17.85c.225 0 .375-.15.375-.375V9.511c0-.225-.15-.375-.375-.375H7.075a.403.403 0 00-.375.375z"
        stroke="url(#envelope-coloured_svg__paint0_linear)"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="envelope-coloured_svg__paint0_linear"
          x1={0.625}
          y1={16}
          x2={31.375}
          y2={16}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFED00" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgEnvelopeColoured;
