import React from 'react';
import sizes from 'react-sizes';
import { ResponsiveVideo } from 'components';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

const getInfoBoxesAndMap = (asideImgs) => (
  <>
    <InfoBox>
      <p>
        <b>Course Times:</b>
        <br />
        <span>Part 1</span>10.00 - 13.00
        <br />
        <span>Lunch </span>13.00 - 14.00
        <br />
        <span>Part 2</span>14.00 - 16.00
        <br />
        <br />
        Options are available for a condensed 5-hour session from 8:30 – 13:30. 
        <br />
        <br />
        <b>Location:</b>
        <br />
        Our Course is held at our Studios on South William Street, Dublin 2.
      </p>
    </InfoBox>
    {asideImgs.edges.slice(3).map((img) => (
      <Map key={img.node.name} fluid={img.node.childImageSharp.fluid} />
    ))}
    <InfoBox>
      <p>
        Light lunch and tea/coffee is included. 
        <br />
        <br />
        <b>Contact and enquiries</b>
        <br />
        For group bookings, calendar availability and further queries, please get in touch: <a href="mailto:contact@getbroadcasting.com" target="_blank">contact@getbroadcasting.com</a>
      </p>
    </InfoBox>
  </>
);

const Info = ({ handleSplashScreen, viewportWidth, pathname }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          asideImgs: allFile(
            filter: { relativeDirectory: { eq: "Training/AsideImgs" } }
            sort: { fields: [relativePath], order: ASC }
          ) {
            edges {
              node {
                name
                childImageSharp {
                  fluid(maxWidth: 711, maxHeight: 711, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      `}
      render={({ asideImgs }) => (
        <Wrapper>
          <Text>
            <ResponsiveVideo
              pathname={pathname}
              videoTitle="on_camera_training"
              handleSplashScreen={handleSplashScreen}
            />
            <br />
            <br />
            <SubHeader>
              On-Camera Training Course
              {/* {viewportWidth >= 904 && <br />}
              {viewportWidth < 904 && <code>&nbsp;</code>}for Senior Executives */}
              <br />
              for Senior Executives
            </SubHeader>
            <Button>(PART 1 AND 2)</Button>
            <p>
              As scalable corporate communication moves ever more towards video, live-stream and
              webinar, the demands on business leaders to perform flawlessly on-camera are
              ever-increasing.
            </p>
            <br />
            <p>
              It’s a challenge that can make or break the effectiveness of the message and in doing
              so; the audience perception of the leader themselves.
            </p>
            <br />
            <p>
              On-camera communication is less an innate talent and much more a learned skill,
              developed deliberately over time across the movie and live TV broadcast industries.
            </p>
            <br />
            <p>
              We have taken the on-camera skills required and collated them into a specialised,
              practical training course for our corporate clients, to give them the edge.
            </p>
            <br />
            <p>We trust it can be of service to you and your teams.</p>
            <br />
            <br />
            <br />
            {viewportWidth < 904 && (
              <PeopleImg
                key={asideImgs.edges[0].node.name}
                fluid={asideImgs.edges[0].node.childImageSharp.fluid}
              />
            )}
            <H2AndGradientHRWrapper>
              <H2>Course Background and Introduction</H2>
              <GradientHR />
            </H2AndGradientHRWrapper>
            <p>
              We influence in many ways and with great frequency. Skills that are viable and honed
              over a lifetime for face to face meetings can benefit from being enhanced when we are
              using electronic channels such as webinars, live streaming and pieces to camera.
            </p>
            <br />
            <p>
              Over the years of working with people on communications we have noticed that there is
              often confusion about what works best when we are trying to move people towards our
              point of view. The initial impression itself may be what&apos;s at issue when we are
              first observed. If this impression is deteriorated by hesitation, over-confidence or a
              failure to connect we may miss an opportunity to achieve our goals. The camera can
              exacerbate these nuances.
            </p>
            <br />
            <p>
              Our course is designed to bring a new level of understanding around what works best
              for each presenter, mitigate any on-camera imperfections and enhance the natural
              communication manner that we individually possess, all while the camera is rolling.
            </p>
            <br />
            <br />
            <br />
            {viewportWidth < 904 && (
              <PeopleImg
                key={asideImgs.edges[1].node.name}
                fluid={asideImgs.edges[1].node.childImageSharp.fluid}
              />
            )}
            <H2AndGradientHRWrapper>
              <H2>On Camera Coach</H2>
              <GradientHR />
            </H2AndGradientHRWrapper>
            <p>
              Our On-Camera Course is delivered by Michael Comyn. Michael is a broadcaster and
              trainer with over 30 years’ experience in radio and television presentation and he has
              put together this programme to support organisations in the area of on-screen video
              presence and delivery. Having the right knowledge and skills when stepping in front of
              the camera can enable you better understand and formulate your message while
              maintaining a professional demeanour for yourself and your organisation.
            </p>
            <br />
            <br />
            <br />
            {viewportWidth < 904 && (
              <PeopleImg
                key={asideImgs.edges[2].node.name}
                fluid={asideImgs.edges[2].node.childImageSharp.fluid}
              />
            )}
            <H2AndGradientHRWrapper>
              <H2>Learning Objectives</H2>
              <GradientHR />
            </H2AndGradientHRWrapper>
            <ul>
              At the end of this course, you will be able to:
              <li>
                Describe different techniques for getting conversations and presentations underway
              </li>
              <li>Make a compelling presentation by using the 5 S’s and the SCRAP formula</li>
              <li>Deliver your message confidently on-camera in a live studio environment</li>
              <li>Apply storytelling techniques to extend influence</li>
            </ul>
            <br />
            <br />
            <br />
            <H2AndGradientHRWrapper>
              <H2>Course Structure</H2>
              <GradientHR />
            </H2AndGradientHRWrapper>
            <p>
              The On-Camera course is run over 5 hours in our discreet South William Street Studio.
              The tailored course content makes full use of the studio equipment including camera,
              sound, lighting as well as the space itself and how to adjust body position.
            </p>
            <br />
            <p>
              The course is designed to be entirely practical and applicable for on camera business
              communications. To achieve best results the course is run for a maximum of 4 people
              and participants learn not only from their own engagement but in observing the efforts
              of others on the training.
            </p>
            <br />
            <br />
            <br />
            <InfoBoxAndMapWrapperWhenOnMobileOrTablet>
              {viewportWidth < 904 && getInfoBoxesAndMap(asideImgs)}
            </InfoBoxAndMapWrapperWhenOnMobileOrTablet>
          </Text>
          {viewportWidth > 903 && (
            <Aside>
              <PeopleImgs>
                {asideImgs.edges.slice(0, 3).map((img) => (
                  <PeopleImg key={img.node.name} fluid={img.node.childImageSharp.fluid} />
                ))}
              </PeopleImgs>
              {getInfoBoxesAndMap(asideImgs)}
            </Aside>
          )}
        </Wrapper>
      )}
    />
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 4.5% 4.5% 0;

  @media screen and (min-width: 400px) {
    padding: 4.5% 9% 0;
  }
`;

const SubHeader = styled.h2`
  text-align: center;

  @media screen and (min-width: 904px) {
    text-align: left;
  }
`;

const Button = styled.div`
  background: linear-gradient(262.46deg, #e4097e 26.97%, #ffef00 122.99%);
  border-radius: 1.5em;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  height: 30px;
  margin: 1.25em auto 2.75em;
  padding: 0.55em 0.8em 0.5em;
  text-align: center;
  width: 118px;

  @media screen and (min-width: 904px) {
    margin: 1.25em 0 2.75em;
  }
`;

const H2AndGradientHRWrapper = styled.div`
  @media screen and (max-width: 599px) {
    width: 100%;
  }

  @media screen and (max-width: 903px) {
    margin-left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  @media screen and (min-width: 904px) {
    display: inline-block;
  }
`;

const H2 = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;

  @media screen and (max-width: 903px) {
    text-align: center;
  }
`;

const GradientHR = styled.div`
  background: linear-gradient(262.46deg, #e4097e 26.97%, #ffef00 122.99%);
  height: 3px;
  margin: 1.05em 0 1.45em;
`;

const Text = styled.div`
  max-width: 711px;

  @media screen and (min-width: 904px) {
    margin-right: 9%;
  }

  h2 {
    font-size: 1.875rem;
  }

  p,
  ul,
  code {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25em;
  }

  li {
    margin-left: 1em;
  }
`;

const Aside = styled.aside`
  max-width: 355px;
`;

const PeopleImgs = styled.div`
  display: flex;
  flex-direction: column;
`;

const PeopleImg = styled(Img)`
  @media screen and (max-width: 903px) {
    margin: 0 auto;
    max-width: 355px;
  }

  &:not(:last-child) {
    margin-bottom: 57px;
  }
`;

const InfoBoxAndMapWrapperWhenOnMobileOrTablet = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 815px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const InfoBox = styled.div`
  background-color: rgba(229, 229, 229, 1);
  line-height: 1.625em;
  padding: 1.625em;

  p {
    color: #000;
    font-size: 1.25rem;
    font-weight: 300;

    b {
      font-weight: 600;
    }

    a {
      color: #000;
    }
  }

  span {
    display: inline-block;
    width: 70px;
  }

  @media screen and (max-width: 814px) {
    &:last-of-type {
      padding-top: 0;
    }
  }

  @media screen and (min-width: 815px) {
    width: 50%;
  }

  @media screen and (min-width: 904px) {
    width: 100%;
  }
`;

const Map = styled(Img)`
  @media screen and (max-width: 903px) {
    order: 3;
    margin-bottom: 57px;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Info);
