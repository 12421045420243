import React from 'react';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import sizes from 'react-sizes';
import { motion } from 'framer-motion';
import { LinkedinShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, LinkedinIcon, WhatsappIcon } from 'images/Blog';
import { getFluidGatsbyImage, getGatsbyImageData } from 'gatsby-source-sanity';
import { getFile } from '@sanity/asset-utils';
import BlockContent from '@sanity/block-content-to-react';
import { useLocation } from '@reach/router';
import RightArrow from 'images/Shared/Misc/RightArrow';

const sanityProjectDetails = { projectId: 'wtrhcvep', dataset: 'production' };

const ContentBody = ({
  children,
  blockContent,
  vimeoVideo,
  endVideo,
  openingLine,
  closingLine,
  viewportWidth,
  hideSocials,
  directionRow,
  smallerMargin,
  slug
}) => {
  const { href } = useLocation();
  return (
    <Wrapper
      href={href}
      children={children}
      directionRow={directionRow}
      smallerMargin={smallerMargin}
      slug={slug}>
      {!hideSocials && (
        <SocialMediaButtons>
          <h3 style={{ textTransform: 'uppercase', margin: '59px 0 17px' }}>Share</h3>
          <div>
            <LinkedinShareButton url={href}>
              <SocialMediaButton>
                <LinkedinIcon />
              </SocialMediaButton>
            </LinkedinShareButton>
            <FacebookShareButton url={href}>
              <SocialMediaButton>
                <FacebookIcon />
              </SocialMediaButton>
            </FacebookShareButton>
            <WhatsappShareButton url={href}>
              <SocialMediaButton>
                <WhatsappIcon />
              </SocialMediaButton>
            </WhatsappShareButton>
          </div>
        </SocialMediaButtons>
      )}
      {vimeoVideo && (
        <div
          className="video-without-caption"
          style={{
            height: '100%',
            maxHeight: '461px',
            maxWidth: '820px',
            paddingTop: '56.25%',
            position: 'relative',
            width: '100%',
            margin: '3.625rem 0'
          }}>
          <iframe
            src={vimeoVideo.url}
            width="820"
            height="461"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen></iframe>
        </div>
      )}
      {openingLine &&
        openingLine.split('<br />').map((text, i, arr) => (
          <h2>
            {text}
            {i + 1 === arr.length && <MultiColouredBottomBorder />}
            {i + 1 === arr.length && <div style={{ height: '48px' }} />}
          </h2>
        ))}
      {children ? (
        children
      ) : (
        <BlockContent
          blocks={blockContent}
          serializers={{
            marks: {
              internalLink: ({ mark, children }) => {
                const { href } = mark;
                return (
                  <GatsbyLink to={href.split('getbroadcasting.com')[1]}>{children}</GatsbyLink>
                );
              },
              externalLink: ({ mark, children }) => {
                const { blank, href } = mark;
                return blank ? (
                  <a href={href} target="_blank" rel="noopener">
                    {children}
                  </a>
                ) : (
                  <a href={href}>{children}</a>
                );
              }
            },
            types: {
              livestreamCompanyLogo({ node }) {
                const imageData = getFluidGatsbyImage(
                  node.image.asset._ref,
                  { maxWidth: 200 },
                  sanityProjectDetails
                );
                return <CompanyImg fluid={imageData} objectFit="contain" alt={node.alt} />;
              },
              blogPostBodyImage({ node }) {
                const { alt, image, caption, linkToFile } = node;
                let fileUrl;
                if (linkToFile) {
                  fileUrl = getFile(linkToFile?.asset, sanityProjectDetails).asset.url;
                }
                const imageData = getFluidGatsbyImage(
                  image.asset._ref,
                  { maxWidth: 820 },
                  sanityProjectDetails
                );
                return caption ? (
                  <a
                    href={linkToFile ? fileUrl : imageData.src.slice(0, imageData.src.indexOf('?'))}
                    target="_blank">
                    <Figure>
                      <FullWidthImage fluid={imageData} alt={alt} />
                      <Caption>{caption}</Caption>
                    </Figure>
                  </a>
                ) : (
                  <a
                    href={linkToFile ? fileUrl : imageData.src.slice(0, imageData.src.indexOf('?'))}
                    target="_blank">
                    <FullWidthImage fluid={imageData} alt={alt} />
                  </a>
                );
              },
              blogPostBodyTwoHalfWidthImages({ node }) {
                const { firstImage, secondImage } = node;
                const imageData = [firstImage, secondImage].map(({ image }) =>
                  getFluidGatsbyImage(image.asset._ref, { maxWidth: 398 }, sanityProjectDetails)
                );
                return (
                  <HalfWidthImagesWrapper>
                    {[firstImage, secondImage].map((image, i) => {
                      let fileUrl;
                      if (image.linkToFile) {
                        fileUrl = getFile(image.linkToFile.asset, sanityProjectDetails).asset.url;
                      }
                      console.log(imageData[i]);
                      return image.caption ? (
                        <a
                          href={
                            image.linkToFile
                              ? fileUrl
                              : imageData[i].src.slice(0, imageData[i].src.indexOf('?'))
                          }
                          target="_blank">
                          <Figure key={i}>
                            <HalfWidthImage fluid={imageData[i]} alt={image.alt} />
                            <Caption>{image.caption}</Caption>
                          </Figure>
                        </a>
                      ) : (
                        <a
                          href={
                            image.linkToFile
                              ? fileUrl
                              : imageData[i].src.slice(0, imageData[i].src.indexOf('?'))
                          }
                          target="_blank">
                          <HalfWidthImage key={i} fluid={imageData[i]} alt={image.alt} />
                        </a>
                      );
                    })}
                  </HalfWidthImagesWrapper>
                );
              },
              companyLogoImage({ node }) {
                const { alt, image } = node;
                const imageData = getFluidGatsbyImage(
                  image.asset._ref,
                  { maxWidth: 250 },
                  sanityProjectDetails
                );
                return <FullWidthImage fluid={imageData} alt={alt} />;
              },
              vimeoVideo({ node }) {
                const { url, caption } = node;
                return caption ? (
                  <Figure>
                    <div
                      style={{
                        height: '100%',
                        maxHeight: '461px',
                        maxWidth: '820px',
                        paddingTop: '56.25%',
                        position: 'relative',
                        width: '100%'
                      }}>
                      <iframe
                        src={url}
                        width="820"
                        height="461"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen></iframe>
                    </div>
                    <Caption>{caption}</Caption>
                  </Figure>
                ) : (
                  <div
                    className="video-without-caption"
                    style={{
                      height: '100%',
                      maxHeight: '461px',
                      maxWidth: '820px',
                      paddingTop: '56.25%',
                      position: 'relative',
                      width: '100%'
                    }}>
                    <iframe
                      src={url}
                      width="820"
                      height="461"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen></iframe>
                  </div>
                );
              },
              block(props) {
                switch (props.node.style) {
                  case 'h3':
                    return <SectionHeader>{props.children}</SectionHeader>;
                  case 'blockquote':
                    return (
                      <Quote>
                        <p>{props.children}</p>
                      </Quote>
                    );
                  default:
                    return BlockContent.defaultSerializers.types.block(props);
                }
              }
            }
          }}
        />
      )}
      {endVideo && (
        <div
          className="video-without-caption"
          style={{
            height: '100%',
            maxHeight: '461px',
            maxWidth: '820px',
            paddingTop: '56.25%',
            position: 'relative',
            width: '100%',
            margin: '3.625rem 0'
          }}>
          <iframe
            src={endVideo}
            width="820"
            height="461"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen></iframe>
        </div>
      )}
      {closingLine && (
        <h4>
          {closingLine.includes('.') && closingLine.split('.').length > 2 ? (
            <>
              <em>{`${closingLine
                .split('. ')
                .slice(0, -1)
                .join('. ')}. `}</em>
              <span>
                {closingLine.split('. ').slice(-1)}
                <MultiColouredBottomBorder />
              </span>
            </>
          ) : (
            <span>
              {closingLine}
              <MultiColouredBottomBorder />
            </span>
          )}
          {!children && (
            <StyledButton to="/services/">
              <StyledRightArrow />
              <ButtonText>All Services</ButtonText>
            </StyledButton>
          )}
        </h4>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  max-width: ${({ children }) => (children ? '750px' : '720px')};
  margin-top: 0;
  width: 100%;

  @media screen and (min-width: 1200px) {
    margin-top: 0;
    margin-bottom: ${({ smallerMargin }) => (smallerMargin ? '3.5rem' : '10rem')};
  }
  div {
    align-items: center;
    display: ${({ directionRow }) => (directionRow ? 'flex' : 'block')};
    justify-content: center;
    gap: 2rem;
  }
  p {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.4em;
    margin-bottom: 1.5em;

    ${({ href }) =>
      href.includes('vlog') &&
      css`
        font-size: 1.05rem;

        &:first-of-type {
          font-size: 1.125rem;

          @media screen and (min-width: 48rem) {
            font-size: 1.25rem;
            line-height: 1.65em;
          }
        }
      `}
  }

  h2 {
    font-weight: 700;
    display: inline-block;

    font-size: 3rem;
    margin: 0.45rem 0 -0.5rem;
    max-width: 700px;
  }

  h4 {
    font-weight: 700;
    display: inline-block;
    font-size: 2.325rem;
    margin: -0.5rem 0 7rem;

    em {
      font-weight: 300;
    }

    span {
      display: inline-block;
      font-weight: 700;
    }
  }

  ul {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.4em;
    margin-bottom: 1.5rem;
    margin-left: 1.875rem;
    list-style-type: none;
    li {
      position: relative;
      margin-bottom: 0.5rem;
      &:before {
        content: '●';
        font-size: 0.75rem;
        margin-left: -1.875rem;
        position: absolute;
      }
    }
    ${({ href }) =>
      href.includes('vlog') &&
      css`
        font-size: 1rem;
      `}
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  div.video-without-caption {
    margin: 2rem 0;
  }
`;

const MultiColouredBottomBorder = styled.div`
  background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
  height: 3px;
  bottom: 2px;
  width: 110%;
  margin-top: 0.75rem;
  position: relative;
`;

const GatsbyLink = styled(Link)``;

const SocialMediaButtons = styled.div`
  margin-bottom: 3.375rem;

  > div {
    width: 6.375rem;
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const SocialMediaButton = styled(motion.div).attrs({
  whileHover: {
    scale: 1.15
  },
  whileTap: {
    scale: 0.85
  }
})`
  align-items: center;
  background: linear-gradient(215.86deg, #e4097e 27.63%, #ffef00 153.42%);
  border-radius: 50%;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  outline: none;
  width: 1.5rem;
`;

const SectionHeader = styled.h3`
  font-size: 2rem;
  margin: 2em 0 1em 0;
  font-weight: 600;
  display: inline-block;
  position: relative;
  &:after {
    content: '';
    background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
    height: 3px;
    bottom: -0.75rem;
    left: 0;
    width: 110%;
    max-width: 720px;
    position: absolute;
  }
`;

export const FullWidthImage = styled(Img)`
  margin: 2rem 0;
  ${({ style }) => style};
`;

const CompanyImg = styled(Img)`
  width: 100%;
  height: 100%;
`;

const HalfWidthImage = styled(Img)`
  margin: 2rem 0;
`;

const Figure = styled.figure`
  margin: 2rem 0;
  ${({ style }) => style};

  > ${FullWidthImage}, > ${HalfWidthImage} {
    margin: 0;
  }
`;

const HalfWidthImagesWrapper = styled.div`
  margin: 2rem 0;

  > ${Figure}, ${HalfWidthImage} {
    margin: 0;
  }

  @media screen and (min-width: 43.75rem) {
    align-items: center;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 24px;
  }
`;

const Caption = styled.figcaption`
  font-size: 0.85rem;
  margin-top: 0.25em;
  color: #3b444b;
  text-align: center;
`;

const Quote = styled.blockquote`
  text-align: center;
  font-style: italic;
  font-size: 1.25rem;
  margin: 2rem auto;

  p {
    max-width: 500px;
    margin: 0 auto;
  }

  @media screen and (min-width: 43.75rem) {
    font-size: 1.5rem;
  }
`;

const StyledButton = styled(Link)`
  align-items: center;
  background: linear-gradient(254.16deg, #e4097e 26.97%, #ffef00 122.99%);
  border-radius: 50% / 250%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 48px;
  justify-content: space-between;
  margin: 2.2em auto 0;
  padding: 0 1.125em;
  width: 210px;
  text-decoration: none;

  @media screen and (min-width: 590px) {
    margin: 2.2em 0 0;
  }
`;

const ButtonText = styled.p`
  color: #fff;
  font-size: calc(0.875rem + 0.4vw);
  font-weight: 600 !important;
  padding: 0;
  margin: 0 !important;
  position: relative;
  left: 0.3em;
  font-family: proxima-nova, sans-serif !important;

  @media screen and (min-width: 900px) {
    font-size: 1.125rem;
  }
`;

const StyledRightArrow = styled(RightArrow)`
  bottom: 1px;
  position: relative;
  right: 0.3em;
  transform: scale(-1);
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(ContentBody);
