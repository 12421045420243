import { createGlobalStyle } from 'styled-components';

const SmallPrintStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  li {
    font-family: open-sans, sans-serif;
    margin: 0;
    padding: 0;
  }

  body {
    font-size: calc(12px + 0.5vw);
    line-height: calc(1.1em + 0.5vw);
  }

  @media screen and (min-width: 996px) {
    body {
      font-size: 16.98px;
      line-height: 23.658px;
    }
  }

  p {
    font-size: calc(9px + 0.5vw);
    color: black;
  }

  @media screen and (min-width: 996px) {
    p {
      font-size: 13.98px;
    }
  }

  h1 {
    font-size: 2em;
    line-height: 1.25em;
  }

  h2 {
    font-size: 1.625em;
    line-height: 1.15384615em;
  }

  h3 {
    font-size: 1.375em;
    line-height: 1.13636364em;
  }

  h4 {
    font-size: 1.125em;
    line-height: 1.11111111em;
  }

  p {
    margin-bottom: 1em;
  }

  h1 {
    text-align: center;
    margin: 0 auto 1em;
  }

  li {
    font-size: 0.725rem;
    margin-left: 1em;
  }

  ol ul {
    margin-left: 2em;
    list-style-type: disc;
  }

  table {
    margin: 1.25em auto;
  }

  th {
    font-weight: normal;
  }

  td,
  th {
    border: 1px solid black;
    padding: 20px;
  }

  .alphabetical-list {
    list-style-type: lower-alpha;
    padding-left: 2em;
  }

  .detailed-list {
    padding-left: 0;
  }

  .detailed-list div {
    margin-left: 0.75em;
  }

  @media screen and (min-width: 767px) {
    li {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 996px) {
    li {
      font-size: 0.9rem;
    }
  }
`;

export default SmallPrintStyle;
