import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import { Link } from 'gatsby';
import LeftArrowIcon from 'images/CaseStudy/ColouredLeftArrowIcon';
import RightArrowIcon from 'images/CaseStudy/ColouredRightArrowIcon';
import ShowAllCases from 'images/CaseStudy/ShowAllCases';

const CaseStudyNavigation = ({ viewportWidth, prevSlug, nextSlug }) => {
  return (
    <Wrapper>
      <GreyBackgroundRectangle>
        <Link disabled={!prevSlug} to={prevSlug ? `/portfolio/${prevSlug}/` : '/'}>
          <LeftArrowIcon />
          <p>Prev</p>
        </Link>
        <Link to="/portfolio/">
          <ShowAllCases />
          <p>Show All</p>
        </Link>
        <Link disabled={!nextSlug} to={nextSlug ? `/portfolio/${nextSlug}/` : '/'}>
          <RightArrowIcon />
          <p>Next</p>
        </Link>
      </GreyBackgroundRectangle>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin: 10.25em auto 5.125em;
`;

const GreyBackgroundRectangle = styled.div`
  background: #f2f2f2;
  display: flex;
  height: 32px;
  justify-content: space-between;
  margin: 0 auto;
  width: 200px;

  @media screen and (min-width: 430px) {
    width: 250px;
  }

  @media screen and (min-width: 560px) {
    width: 300px;
  }

  @media screen and (min-width: 800px) {
    width: 350px;
  }

  @media screen and (min-width: 900px) {
    width: 400px;
  }

  a {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 59px;
    justify-content: space-between;
    position: relative;
    text-decoration: none;

    p,
    p:visited {
      color: #000;
    }
  }

  a:first-child {
    right: 16px;
  }

  a:last-child {
    left: 16px;
  }

  a[disabled] {
    pointer-events: none;

    svg circle {
      fill: #E5E5E5;
    }
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(CaseStudyNavigation);
