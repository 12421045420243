import * as React from 'react';

function SvgGreyMapMarker(props) {
  return (
    <svg width={11} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        clipPath="url(#grey-map-marker_svg__clip0)"
        stroke="#8B8A8A"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M9.8 5.2c0 2.6-4.7 9.1-4.7 9.1S.5 7.7.5 5.2 2.6.5 5.2.5s4.6 2.1 4.6 4.7z" />
        <path d="M5.2 7.1a1.9 1.9 0 100-3.8 1.9 1.9 0 000 3.8z" />
      </g>
      <defs>
        <clipPath id="grey-map-marker_svg__clip0">
          <path fill="#fff" d="M0 0h10.3v14.7H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgGreyMapMarker;
