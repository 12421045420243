import React, { useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

const MeetTheTeam = () => {
  const [hovered, setHovered] = useState(null);
  const teamMemberDetails = [
    { name: 'Mark Nolan', title: 'Co-Founder & Director' },
    { name: 'Nirina Plunkett', title: 'Production Manager & Client Relations' },
    { name: "David O'Reilly", title: 'Creative Director' },
    { name: "Brian Larkin", title: 'Co-Founder & Director' },
    { name: 'Kevin McCabe', title: 'Audio Engineer & Senior Editor' },
    { name: 'Aidan McDonagh', title: 'Broadcast Manager' },
    { name: 'Arturo Muñiz Sánchez', title: 'Animations & Editor' }
  ];
  return (
    <Container>
      <h4>
        <span>
          Meet The Team
          <MultiColouredBottomBorder style={{ marginLeft: '-5%' }} />
        </span>
      </h4>
      {/* <p>
        Learn more about your Get Broadcasting Team. From those who you liase with via email, to
        those who produce your videos, animate your graphics, or produce the voice overs, to those
        who broadcast your live events.
      </p> */}

      <StaticQuery
        query={graphql`
          {
            teamPhotos: allFile(
              filter: { relativeDirectory: { regex: "/AboutUs/MeetTheTeam/" } }
              sort: { fields: [relativeDirectory], order: ASC }
            ) {
              edges {
                node {
                  name
                  childImageSharp {
                    fixed(width: 240, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ teamPhotos }) => (
          <TeamMembers>
            {teamPhotos.edges.map(({ node: teamMemberPhoto }, index) => (
              <Card>
                <CardImgContainer
                  onMouseEnter={() => setHovered(index)}
                  onMouseLeave={() => setHovered(null)}
                  hovered={hovered === index}>
                  <CardImgBorder />
                  <CardImg
                    fixed={teamMemberPhoto.childImageSharp.fixed}
                    key={teamMemberDetails[index].name}
                    alt={teamMemberDetails[index].name}
                    hovered={hovered === index}
                  />
                </CardImgContainer>
                <CardTitle>{teamMemberDetails[index].name}</CardTitle>
                <CardSubtitle>{teamMemberDetails[index].title}</CardSubtitle>
              </Card>
            ))}
          </TeamMembers>
        )}
      />
    </Container>
  );
};

const MultiColouredBottomBorder = styled.div`
  background: linear-gradient(269.94deg, #e4097e 63.91%, #ffef00 100%);
  height: 3px;
  bottom: 2px;
  width: 110%;
  margin-top: 0.75rem;
  position: relative;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  h4 {
    font-weight: 700;
    display: inline-block;
    font-size: 2.325rem;
    text-align: center;
    margin: 3rem 0;

    em {
      font-weight: 300;
    }

    span {
      display: inline-block;
      font-weight: 700;
    }
  }
  p {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: center;
  }
`;

const TeamMembers = styled.div`
  box-sizing: border-box;
  display: grid !important;
  grid-row-gap: 1.5rem;
  grid-template-columns: repeat(1, 1fr);
  position: relative;
  width: 100%;
  margin: 3rem auto;
  @media screen and (min-width: 640px) {
    grid-row-gap: 3rem;
    grid-column-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    padding: 0 2rem;
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    padding: 0 3rem;
    margin: 4rem auto;
  }
`;

const CardImgContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const CardImg = styled(Img)`
  border-radius: 50%;
  height: 240px !important;
  object-fit: cover;
  object-position: center;
  width: 240px !important;
  transition: all 0.5s ease-in-out;
  @media screen and (min-width: 1024px) {
    filter: ${({ hovered }) => (hovered ? 'grayscale(0%)' : 'grayscale(100%)')};
  }
`;

const CardImgBorder = styled.div`
  background: linear-gradient(to right, rgba(228, 9, 126, 1), rgba(255, 239, 0, 1));
  border-radius: 50%;
  top: -2px;
  left: -2px;
  height: 244px !important;
  position: absolute;
  width: 244px !important;
`;

const Card = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  max-width: 250px;
  margin: 0 auto;

  @media screen and (min-width: 640px) {
    /* Center poor ol' Arturo */
    &:last-of-type {
      left: 50%;
    }
  }

  @media screen and (min-width: 1024px) {
    /* Center poor ol' Arturo */
    &:last-of-type {
      left: initial;
      grid-column: 2 / 3;
    }
  }
`;

const CardTitle = styled.h5`
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-align: center;
`;

const CardSubtitle = styled.p`
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
`;

export default MeetTheTeam;
