import React, { PureComponent, createRef } from 'react';
import sizes from 'react-sizes';
import { isIOS, isSafari } from 'react-device-detect';
import Img from 'gatsby-image';
import editFilename from 'helpers/editFilename';
import { StaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';

class ContactForm extends PureComponent {
  state = {
    name: '',
    email: '',
    message: '',
    file: '',
    showSuccessMessage: false,
    isIOS: null,
    isSafari: null
  };

  fileLabelRef = createRef();

  componentDidMount() {
    this.setState({
      isIOS,
      isSafari
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /* See 'JavaScript Enhancement' section...
  https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/ */
  handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      this.fileLabelRef.current.style.outline = 'none';
      this.fileLabelRef.current.innerHTML = '&#10003;';
      this.fileLabelRef.current.style.fontWeight = '400';
      this.setState({ file });
    }
  };

  encodeData = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    return formData;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const { name, email, message, file } = this.state;
    fetch('/', {
      method: 'POST',
      body: this.encodeData({
        'form-name': form.getAttribute('name'),
        name,
        email,
        message,
        file
      })
    })
      .then(() => {
        this.setState({
          name: '',
          email: '',
          message: '',
          file: '',
          showSuccessMessage: true
        });
      })
      .catch(console.error);
  };

  render() {
    const { name, email, message, file, showSuccessMessage, isIOS, isSafari } = this.state;
    return (
      isIOS !== null && isSafari !== null && (
        <Wrapper pathname={this.props.pathname}>
          <StaticQuery
            query={graphql`
              {
                backgroundImg: file(
                  relativePath: { eq: "Shared/ContactUsForm/fingers-typing-on-keyboard.jpg" }
                ) {
                  name
                  childImageSharp {
                    fluid(maxWidth: 1440) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            `}
            render={({ backgroundImg }) => (
              <BackgroundImg
                alt={editFilename(backgroundImg.name)}
                fluid={backgroundImg.childImageSharp.fluid}
              />
            )}
          />
          {showSuccessMessage ? (
            <SuccessMessage>Thanks - we&apos;ll be in touch shortly!</SuccessMessage>
          ) : (
            <Form
              data-netlify-honeypot="bot-field"
              data-netlify="true"
              method="post"
              name="contact"
              onSubmit={(e) => this.handleSubmit(e)}>
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <Input type="hidden" name="form-name" value="contact" />
              <div hidden>
                <Label>
                  Don’t fill this out:
                  <Input name="bot-field" onChange={this.handleChange} />
                </Label>
              </div>
              <NameAndEmailWrapper>
                <Label>
                  Your Name<Asterisks>*</Asterisks>
                  <GradientBorder isIOS={isIOS} isSafari={isSafari}>
                    <Input
                      aria-label="Enter your name"
                      name="name"
                      onChange={this.handleChange}
                      placeholder="Enter your name"
                      required
                      type="text"
                      value={name}
                    />
                  </GradientBorder>
                </Label>
                <Label>
                  Your Email Address<Asterisks>*</Asterisks>
                  <GradientBorder isIOS={isIOS} isSafari={isSafari}>
                    <Input
                      aria-label="Enter your email"
                      name="email"
                      onChange={this.handleChange}
                      placeholder="Enter your email"
                      required
                      type="email"
                      value={email}
                    />
                  </GradientBorder>
                </Label>
              </NameAndEmailWrapper>
              <Label>
                Your Query<Asterisks>*</Asterisks>
                <GradientBorder style={{ clipPath: 'inset(0 0 5px 0)' }} isIOS={isIOS} isSafari={isSafari}>
                  <TextArea
                    aria-label="Enter your message"
                    as="textarea"
                    cols="30"
                    name="message"
                    onChange={this.handleChange}
                    placeholder="Enter your message"
                    required
                    rows="10"
                    value={message}
                  />
                </GradientBorder>
              </Label>
              <p>Attatch A File</p>
              <AttatchFileAndSendButtonWrapper>
                <LabelAndUploadedFileNameWrapper>
                  {/* I'm putting the Input outside the Label here so that a user can select another file if they upload the wrong file. If Input was a child of Label then it would be removed from the DOM when the first file is uploaded, due to '.innerHTML' replacing the element with a success tick. */}
                  <Input
                    aria-label="Attatch a File"
                    id="file"
                    name="file"
                    onChange={this.handleFileUpload}
                    type="file"
                  />
                  <Label htmlFor="file" ref={this.fileLabelRef}>
                    Choose File
                  </Label>
                  <UploadedFileName>{file && file.name}</UploadedFileName>
                </LabelAndUploadedFileNameWrapper>
                <SendButton type="submit">Send</SendButton>
              </AttatchFileAndSendButtonWrapper>
            </Form>
          )}
        </Wrapper>
      )
    );
  }
}

const Wrapper = styled.section`
  margin: ${({ pathname }) => (pathname === '/contact-us/' ? '0 auto' : '5.2em auto 0')};
  position: relative;
`;

const BackgroundImg = styled(Img)`
  height: 675px;
  left: 0;
  position: absolute;
  top: 0;

  @media screen and (min-width: 980px) {
    height: 601px;
  }
`;

const Form = styled.form`
  left: 2.5%;
  position: absolute;
  top: 6%;
  width: 95%;

  /* "Attatch a File" */
  > p {
    color: #fff;
    font-size: 1.125rem;
    line-height: 1.375em;
    margin-top: 1.688em;
  }

  @media screen and (min-width: 535px) {
    left: 50%;
    margin-left: -253px;
    width: 506px;
  }

  @media screen and (min-width: 980px) {
    display: flex;
    flex-direction: column;
    margin-left: -470px;
    top: 14.5%;
    width: 940px;
  }
`;

const Label = styled.label`
  color: #fff;
  font-size: 1.125rem;
  letter-spacing: 0.01em;
  line-height: 1.375em;
`;

const NameAndEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 1em;
  }

  @media screen and (min-width: 980px) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.688em;

    label {
      margin-bottom: 0;
      max-width: 460px;
      width: 100%;

      &:first-of-type {
        margin-right: 20px;
      }
    }
  }
`;

const Asterisks = styled.span`
  color: #e4097e;
  margin-left: 3px;
`;

const GradientBorder = styled.div`
  /* Gradient border doesn't look good on iOS, so only showing it if not on iOS.  */
  ${({ isIOS, isSafari }) =>
    !isIOS && !isSafari &&
    css`
      background: linear-gradient(262.46deg, #e4097e 26.97%, #ffef00 122.99%);
      margin-top: 0.5em;
      padding: 2px;
    `}
`;

const Input = styled.input`
  background-color: #f4f4f4;
  border-radius: 0.125em;
  border: 1px solid #f4f4f4;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 0.01em;
  padding: 0.5em;
  width: 100%;

  &::placeholder {
    color: #c4c4c4;
    font-style: italic;
  }
`;

const TextArea = styled(Input)`
  height: 174px;
  resize: none;
  width: 100%;
`;

const AttatchFileAndSendButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 980px) {
    align-items: flex-end;
    flex-direction: row;
  }

  label {
    background: linear-gradient(180deg, rgba(228, 9, 126, 0.15) 0%, rgba(255, 239, 0, 0.15) 100%);
    border-radius: 1.5em;
    border: 1px solid white;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 600;
    padding: 0.5em 1.5em;
  }

  input {
    /* https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/ */
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;

    &:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
`;

const LabelAndUploadedFileNameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5em;
`;

const UploadedFileName = styled.span`
  align-self: center;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.375em;
  margin-left: 1em;
`;

const SendButton = styled.button`
  align-self: center;
  background: linear-gradient(262.46deg, #e4097e 26.97%, #ffef00 122.99%);
  border-radius: 1.5em;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 600;
  height: 44px;
  padding: 0.5em 1.75em;
  margin-top: 2.2em;

  @media screen and (min-width: 980px) {
    margin-top: 0.5em;
    align-self: flex-end;
  }
`;

const SuccessMessage = styled.p`
  color: #fff;
  font-size: 2rem;
  font-weight: 100;
  text-align: center;
  position: absolute;
  top: calc(50% - 2rem);
  width: 100%;

  @media screen and (min-width: 440px) {
    font-size: 2.75rem;
    top: calc(50% - 2.75rem);
  }
  @media screen and (min-width: 610px) {
    font-size: 3.75rem;
    top: calc(50% - 3.75rem);
  }
  @media screen and (min-width: 860px) {
    font-size: 4rem;
    top: calc(50% - 4rem);
  }
  @media screen and (min-width: 1200px) {
    font-size: 4.5rem;
    top: calc(50% - 4.5rem);
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(ContactForm);
