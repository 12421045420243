import * as React from 'react';

function SvgLightbulbIcon(props) {
  return (
    <svg width={60} height={60} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M42.632 34.368l3.315 1.369M59.474 30c0 16.263-13.21 29.474-29.474 29.474C13.737 59.474.526 46.264.526 30 .526 13.737 13.736.526 30 .526 46.263.526 59.474 13.736 59.474 30zm-20.158-1c0-5.526-4.79-9.895-10.369-9.263-4.21.474-7.631 3.79-8.157 8-.369 2.947.631 5.684 2.473 7.631 1.632 1.737 2.474 4.106 2.474 6.527h8.579c0-2.421.895-4.79 2.579-6.58 1.473-1.683 2.42-3.894 2.42-6.315zM25.789 44.158h8.474-8.473zm.737 2h7-7zM28 48.105h4-4zm2-25c-3.316 0-6 2.684-6 6l6-6zm0-11.21v3.579-3.58zm12.21 5.052l-2.526 2.527 2.527-2.527zm5.053 12.158h-3.579 3.58zm-34.526 0h3.579-3.58zm5.053-12.158l2.526 2.527-2.526-2.527zm5.631-3.79l1.369 3.317-1.369-3.316zm13.158 0l-1.368 3.317 1.368-3.316zm-22.526 22.58l3.21-1.316-3.21 1.316zm28.579-11.842l3.315-1.369-3.315 1.369zm-28.58-1.369l3.317 1.369-3.316-1.369z"
        stroke="url(#lightbulb-icon_svg__paint0_linear)"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="lightbulb-icon_svg__paint0_linear"
          x1={0}
          y1={30}
          x2={60}
          y2={30}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFED00" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgLightbulbIcon;
