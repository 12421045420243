import React, { PureComponent } from 'react';
import CookieConsent from 'react-cookie-consent';
import { isMobile } from 'react-device-detect';
import throttle from 'lodash.throttle';
import Img from 'gatsby-image';
import CloseModalBlack from 'images/Shared/Misc/close_modal_black.svg';
import CloseModalWhite from 'images/Shared/Misc/close_modal_white.svg';
import { StaticQuery, graphql } from 'gatsby';
import Modal from 'react-modal';
import styled, { css } from 'styled-components';
import { Footer, Header } from 'components';
import { GlobalStyle } from 'sharedStyles';

const customStyles = (viewportWidth) => {
  return {
    overlay: {
      zIndex: 6
    },
    content: {
      border: 'none',
      borderRadius: 0,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      overflow: viewportWidth < 900 ? 'auto' : 'hidden'
    }
  };
};

class Layout extends PureComponent {
  state = {
    headerBackgroundIsTransparent: false,
    isMobile: null,
    viewportWidth: null,
    covid19ModalIsOpen: false
  };

  handleWindowResize = throttle(() => {
    const viewportWidth = window.innerWidth;
    this.setState((prevState) => {
      if (viewportWidth !== prevState.viewportWidth) {
        return {
          viewportWidth
        };
      }
    });
  }, 100);

  componentDidMount() {
    if (window.___INITIAL_RENDER) {
      setTimeout(() => {
        this.handleSplashScreen();
      }, 750);
    }
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('scroll', this.getPageYOffset);
    this.setState({
      isMobile
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getPageYOffset);
  }

  getPageYOffset = throttle(() => {
    this.setState((prevState) => {
      if (!prevState.headerBackgroundIsTransparent && window.pageYOffset > 10) {
        return {
          headerBackgroundIsTransparent: true
        };
      }
      if (prevState.headerBackgroundIsTransparent && window.pageYOffset === 0) {
        return {
          headerBackgroundIsTransparent: false
        };
      }
    });
  }, 250);

  closeCovid19Modal = () => {
    this.setState({
      covid19ModalIsOpen: false
    });
  };

  handleSplashScreen = () => {
    window.fadeOutSplashScreen();
  };

  render() {
    const { children, location } = this.props;
    const { pathname } = location !== null && location !== undefined && location;
    const { headerBackgroundIsTransparent, isMobile, covid19ModalIsOpen, viewportWidth } = this.state;

    return (
      isMobile !== null && (
        <StaticQuery
          query={LAYOUT_QUERY}
          render={({ modalImg }) => (
            <>
              <Wrapper pathname={pathname} isMobile={isMobile}>
                <GlobalStyle />
                {/* <Modal
                  isOpen={window !== undefined && window.___INITIAL_RENDER && covid19ModalIsOpen}
                  onRequestClose={this.closeCovid19Modal}
                  style={customStyles(viewportWidth)}
                  closeTimeoutMS={500}
                  contentLabel="Modal">
                  <ModalInnerWrapper>
                    <CloseModal
                      onClick={this.closeCovid19Modal}
                      src={viewportWidth < 900 ? CloseModalBlack : CloseModalWhite}
                    />
                    <ModalText>
                      <h2>Get Broadcasting is open for business during this challenging time</h2>
                      <br />
                      <p>
                        We can help your business communications ADAPT TODAY to the challenges the
                        COVID 19 crisis is creating.
                      </p>
                      <br />
                      <p>
                        With our own state-of-the-art studio, live streaming capabilities &
                        professional post-production facilities, we'll ensure your business stays
                        connected to your clients, customers & staff.
                      </p>
                      <br />
                      <p>
                        We offer a complete digital communications plan, from creative strategy to
                        production of Live Stream, pre-recorded & animated messaging.
                      </p>
                      <br />
                      <p>
                        Contact us at{' '}
                        <a href="mailto:contact@getbroadcasting.com">
                          <b>contact@getbroadcasting.com</b>
                        </a>
                        <br />
                        and stay safe.
                      </p>
                      <br />
                      {viewportWidth < 440 && <br />}
                    </ModalText>
                    <ModalImg fluid={modalImg.childImageSharp.fluid} />
                  </ModalInnerWrapper>
                </Modal> */}
                <Header pathname={pathname} headerBackgroundIsTransparent={headerBackgroundIsTransparent} />
                <main>{children}</main>
                <Footer />
                <CookieConsent
                  location="bottom"
                  buttonText="OK"
                  style={{
                    background: 'rgba(0, 0, 0, 1)',
                    fontFamily: "'open-sans', sans-serif",
                    fontSize: '14px'
                  }}
                  contentStyle={{ margin: '0.75em 0.75em' }}
                  buttonStyle={{
                    background: '#fff',
                    color: '#4e503b',
                    cursor: 'pointer',
                    fontFamily: "'open-sans', sans-serif",
                    fontSize: '13px',
                    fontWeight: 'bold',
                    margin: '0.75em 0.75em',
                    padding: '0.4em 1.5em'
                  }}>
                  This website uses cookies to enhance the user experience.
                  <a
                    href="/cookie-policy/"
                    target="_blank"
                    style={{ color: '#fff', fontSize: '12px', marginLeft: '0.75em' }}>
                    More about our cookies
                  </a>
                </CookieConsent>
              </Wrapper>
            </>
          )}
        />
      )
    );
  }
}

const CloseModal = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 18px;
  z-index: 1;
  cursor: pointer;

  @media screen and (min-width: 400px) {
    top: 20px;
    right: 20px;
  }
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  @media screen and (min-width: 900px) {
    background: linear-gradient(237.81deg, #cccccc 1.58%, #f5f5f5 71.07%);
    width: 856px;
    height: 600px;
    flex-direction: row;
  }
`;

const ModalText = styled.div`
  padding: 36px 22px 22px;

  h2,
  p,
  a {
    color: #000;
    font-family: montserrat, sans-serif;
  }

  h2 {
    font-size: 1.625rem;
    width: 95%;
    line-height: 1.385em;
  }

  p {
    font-size: 1rem;
    line-height: 1.375em;
  }

  a {
    font-weight: bold;
  }

  @media screen and (min-width: 570px) {
    padding: 36px 33px 33px;
  }

  @media screen and (min-width: 900px) {
    width: 438px;
    height: 600px;

    h2 {
      width: 100%;
      line-height: 36px;
    }
  }
`;

const ModalImg = styled(Img)`
  width: 100%;
  height: 100%;
  max-width: 418px;
  max-height: 600px;

  @media screen and (min-width: 440px) and (max-width: 899px) {
    margin-bottom: 36px;
  }
`;

const Wrapper = styled.div`
  border-color: #000;
  border-style: solid;
  border-width: 0 1px 0;
  max-width: 1440px;
  margin: 0 auto;
  main {
    overflow-x: hidden;
  }
  ${({ pathname }) =>
    pathname === '/' &&
    isMobile &&
    css`
      main {
        position: relative;
        top: 67px;
      }
    `}
`;

const LAYOUT_QUERY = graphql`
  {
    modalImg: file(relativePath: { eq: "Shared/Misc/modal_img.png" }) {
      childImageSharp {
        fluid(maxWidth: 418, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Layout;
