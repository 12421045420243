import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import ColouredTelephoneIcon from 'images/ContactUs/StreetPhotoAndContactDetails/TelephoneColoured';
import ColouredEnvelopeIcon from 'images/ContactUs/StreetPhotoAndContactDetails/EnvelopeColoured';
import ColouredMapMarkerIcon from 'images/ContactUs/StreetPhotoAndContactDetails/MapMarkerColoured';

const StreetPhotoAndContactDetails = ({ viewportWidth }) => {
  return (
    <Wrapper>
      <ContactDetails>
        <List>
          <ListItem>
            <span>
              <ColouredTelephoneIcon />
            </span>
            +353 1 675 5050
          </ListItem>
          <ListItem>
            <span>
              <ColouredEnvelopeIcon />
            </span>
            contact@getbroadcasting.com
          </ListItem>
          <ListItem>
            <span>
              <ColouredMapMarkerIcon />
            </span>
             12 Hatch Street Lower,
            <br /> Dublin 2, D02 R682
          </ListItem>
        </List>
      </ContactDetails>
      <StaticQuery
        query={graphql`
          {
            streetPhoto: file(
              relativePath: {
                eq: "ContactUs/StreetPhotoAndContactDetails/staff-pic.png"
              }
            ) {
              name
              childImageSharp {
                fluid(maxWidth: 720, quality: 65) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        `}
        render={({ streetPhoto }) => (
          <StreetPhoto
            fluid={streetPhoto.childImageSharp.fluid}
            alt={streetPhoto.name.replace(/-/g, ' ')}
          />
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 5.5em auto 0;

  @media screen and (min-width: 1000px) {
    flex-direction: row-reverse;
  }
`;

const StreetPhoto = styled(Img)`
  @media screen and (min-width: 1000px) {
    width: 50%;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 5.5em;

  @media screen and (min-width: 1000px) {
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    width: 50%;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  height: 190px;
  margin-right: 5%;
  justify-content: space-between;
  list-style: none;

  &:first-of-type li span {
    display: inline-flex;
    margin-right: 1.1em;
  }
`;

const ListItem = styled.li`
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 100%;
  margin-left: 0;

  @media screen and (min-width: 360px) {
    font-size: 1.25rem;
  }

  @media screen and (min-width: 435px) {
    font-size: 1.5rem;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  StreetPhotoAndContactDetails
);
