import React, { PureComponent, createRef } from 'react';
import { StaticQuery } from 'gatsby';
import { Button, Card } from 'components';
import { scroller } from 'react-scroll';
import styled, { css } from 'styled-components';
import RightArrow from 'images/Shared/Misc/RightArrow';

class Cards extends PureComponent {
  state = {
    cardsPerPage: 9,
    currentPage: 1,
    cards: []
  };

  leftArrow = createRef();

  rightArrow = createRef();

  static getDerivedStateFromProps({ cards }) {
    return {
      cards
    };
  }

  componentDidUpdate(prevProps, prevState) {
    /* Resets the currentPage to 1 if you're currently on another page and you click on another
    filter */
    if (prevState.cards !== this.props.cards) {
      this.setState({
        currentPage: 1
      });
    }
  }

  scrollToTopOfGrid = (e, callback) => {
    const scrollDuration = 1000;
    const { viewportWidth } = this.props;

    scroller.scrollTo('top-of-grid', {
      duration: scrollDuration,
      delay: 0,
      offset:
        viewportWidth < 360 ? -90 : viewportWidth < 375 ? -96 : viewportWidth < 967 ? 114 : -146,
      smooth: 'easeInOutQuint'
    });

    callback(e);
  };

  handlePageNumberClick = (e) => {
    this.setState({
      currentPage: Number(e.target.innerText)
    });
  };

  handleArrowClick = (e) => {
    if (e.target === this.leftArrow.current) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1
      }));
    } else if (e.target === this.rightArrow.current) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1
      }));
    }
  };

  render() {
    const { currentPage, cardsPerPage, cards } = this.state;

    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;

    const paginatedCards = cards?.slice(indexOfFirstCard, indexOfLastCard);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(cards?.length / cardsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div style={{ padding: '0 12px' }}>
        <Wrapper name="top-of-grid">
          <Grid gridIsEmpty={!cards.length}>
            {cards.length ? (
              paginatedCards.map((card) => (
                <Card
                  mainImage={card.mainImage ? card.mainImage : null}
                  title={card.title}
                  author={card.author}
                  summary={card.summary}
                  slug={card.slug.current}
                  publishedAt={card.publishedAt}
                  key={card.id}
                />
              ))
            ) : (
              <NoCards>
                There are currently no blog posts.
                <br />
                Please check back again soon.
              </NoCards>
            )}
          </Grid>
          {cards.length > cardsPerPage && (
            <PageNumbers pages={Math.ceil(cards.length / cardsPerPage)}>
              <PrevOrNextPageButton
                ref={this.leftArrow}
                boxShadow={
                  currentPage !== pageNumbers[0] ? '0px 4px 4px rgba(79, 79, 79, 0.2)' : 'none'
                }
                onClick={(e) => {
                  this.scrollToTopOfGrid(e, this.handleArrowClick);
                }}
                disabled={currentPage === pageNumbers[0]}>
                <LeftArrow
                  style={{
                    transform: 'scale(-0.625)',
                    transformOrigin: 'center center',
                    pointerEvents: 'none'
                  }}
                />
              </PrevOrNextPageButton>
              {pageNumbers.map((number) => (
                <PageNumber
                  key={number}
                  id={number}
                  onClick={(e) => {
                    this.scrollToTopOfGrid(e, this.handlePageNumberClick);
                  }}
                  selected={number === currentPage}>
                  <div>{number}</div>
                </PageNumber>
              ))}
              <PrevOrNextPageButton
                ref={this.rightArrow}
                boxShadow={
                  currentPage !== pageNumbers.length ? '0px 4px 4px rgba(79, 79, 79, 0.2)' : 'none'
                }
                onClick={(e) => {
                  this.scrollToTopOfGrid(e, this.handleArrowClick);
                }}
                disabled={currentPage === pageNumbers.length}>
                <RightArrow
                  style={{
                    transform: 'scale(0.625)',
                    transformOrigin: 'center center',
                    position: 'relative',
                    left: '1px',
                    pointerEvents: 'none'
                  }}
                />
              </PrevOrNextPageButton>
            </PageNumbers>
          )}
        </Wrapper>
      </div>
    );
  }
}

export const LeftArrow = styled(RightArrow)`
  margin-right: 2px;
  transform: rotate(180deg);
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 60px auto 0;
  max-width: 1180px;

  @media screen and (min-width: 1180px) {
    margin: 120px auto 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(auto, ${({ gridIsEmpty }) => (gridIsEmpty ? 'auto' : '350px')})
  );
  /* grid-auto-rows: minmax(474px, auto); */
  grid-auto-rows: minmax(auto, auto);
  grid-gap: 60px 64px;
  justify-content: center;
  margin-bottom: 6.5rem;
`;

const NoCards = styled.div`
  color: #000;
  font-family: proxima-nova, sans-serif;
  font-size: 2rem;
  letter-spacing: 0.01em;
  text-align: center;
`;

const PageNumbers = styled.ul`
  align-items: center;
  align-self: center;
  display: flex;
  list-style: none;
  max-width: ${({ pages }) => pages * 56 + 64}px;
  width: 100%;
  margin-bottom: 5em;
  justify-content: space-between;

  button,
  li {
  }
  button {
    margin: 0;
  }
`;

const PageNumber = styled.li`
  align-items: center;
  background: ${({ selected }) =>
    selected ? '#e4097e' : 'linear-gradient(90.91deg, #E4097E 31.82%, #FFEF00 62.92%)'};
  border-radius: 50%;
  color: ${({ selected }) => (selected ? '#fff' : '#000')};
  cursor: pointer;
  display: flex;
  font-family: proxima-nova, sans-serif;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 600;
  height: 2rem;
  justify-content: center;
  user-select: none;
  width: 2rem;

  > div {
    align-items: center;
    background: ${({ selected }) =>
      selected ? '#e4097e' : 'linear-gradient(180deg, #fddfec 0%, #fffde2 100%)'};
    border-radius: 50%;
    display: flex;
    height: 1.867em;
    justify-content: center;
    width: 1.867em;
  }
`;

const PrevOrNextPageButton = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ disabled }) =>
    disabled ? '#e5e5e5' : 'linear-gradient(215.86deg, #E4097E 27.63%, #FFEF00 153.42%)'};
`;

const ButtonExt = styled(React.forwardRef((props, ref) => <Button {...props} innerRef={ref} />))``;

export default Cards;
