import * as React from 'react';

function SvgFacebookIcon(props) {
  return (
    <svg width={6} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.404 7.05v6.087c0 .1.072.149.145.149h2.227c.097 0 .146-.074.146-.149V6.951H5.52c.072 0 .145-.075.145-.149l.145-1.856c0-.099-.072-.173-.145-.173H3.922V3.461c0-.321.242-.569.557-.569h1.235c.096 0 .145-.074.145-.148V.863c0-.074-.073-.149-.145-.149H3.63c-1.235 0-2.227 1.015-2.227 2.277v1.807H.29c-.073-.025-.145.05-.145.123v1.856c0 .1.072.149.145.149h1.114v.124z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgFacebookIcon;
