import * as React from 'react';

function SvgMapMarkerColoured(props) {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30.634 16c0 8.085-6.549 14.634-14.634 14.634S1.366 24.085 1.366 16 7.915 1.366 16 1.366 30.634 7.915 30.634 16zM16 5.902a6.831 6.831 0 00-6.842 6.842C9.158 16.512 16 26.098 16 26.098s6.841-9.55 6.841-13.354A6.808 6.808 0 0016 5.902zM16 10a2.78 2.78 0 00-2.78 2.78A2.78 2.78 0 0016 15.562a2.78 2.78 0 002.78-2.78A2.78 2.78 0 0016 10z"
        stroke="url(#map-marker-coloured_svg__paint0_linear)"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="map-marker-coloured_svg__paint0_linear"
          x1={1}
          y1={16}
          x2={31}
          y2={16}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFED00" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgMapMarkerColoured;
