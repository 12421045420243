import React from 'react';
import sizes from 'react-sizes';
import Img from 'gatsby-image';
import editFilename from 'helpers/editFilename';
import { graphql, Link, withPrefix, useStaticQuery } from 'gatsby';
import FilePlayer from 'react-player/lib/players/FilePlayer';
import styled, { css } from 'styled-components';

const LoopingVideoPreview = ({ videoTitle, viewportWidth }) => {
  console.log(videoTitle);
  return (
    <ResponsiveWrapper>
      <StyledFilePlayer
        height="100%"
        loop
        muted
        playing
        playsinline
        url={[
          {
            src: withPrefix(
              `videos/previews/${videoTitle}${
                viewportWidth <= 426 ? '_426' : viewportWidth <= 640 ? '_640' : '_848'
              }w.mp4`
            ),
            type: 'video/mp4'
          }
        ]}
        volume={0}
        width="100%"
      />
    </ResponsiveWrapper>
  );
};

const ResponsiveWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const StyledFilePlayer = styled(FilePlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const CaseStudyPreviews = ({ viewportWidth, pathname }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { sourceInstanceName: { eq: "caseStudies" } }
        sort: { fields: [relativePath], order: [ASC] }
      ) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                clientName
                slug
                showUnderlay
                staticPreviewImg {
                  name
                  childImageSharp {
                    fluid(maxWidth: 719, maxHeight: 403, quality: 70) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                title
                videoTitle
              }
            }
          }
        }
      }
    }
  `);
  const { edges: caseStudyPreviews } = data.allFile;
  return (
    <Wrapper pathname={pathname} centerLastCaseStudy={caseStudyPreviews.length % 2 !== 0}>
      {caseStudyPreviews.map(({ node: caseStudyPreview }) => {
        const {
          clientName,
          slug,
          staticPreviewImg,
          title,
          videoTitle,
          showUnderlay
        } = caseStudyPreview.childMarkdownRemark.frontmatter;
        console.log(caseStudyPreview.childMarkdownRemark.frontmatter);
        return (
          <CaseStudyPreview to={slug === 'get-broadcasting-talent-solutions' ? '/services/talent-solutions' : `/portfolio/${slug}/`} key={slug}>
            {staticPreviewImg ? (
              <BackgroundImg
                fluid={staticPreviewImg.childImageSharp.fluid}
                alt={editFilename(staticPreviewImg.name)}
              />
            ) : (
              <LoopingVideoPreview viewportWidth={viewportWidth} videoTitle={videoTitle} />
            )}
            {showUnderlay && <Underlay />}
            <CaseStudyDetails>
              <ClientName>{clientName}</ClientName>
              <CampaignName dangerouslySetInnerHTML={{ __html: title }} />
            </CaseStudyDetails>
          </CaseStudyPreview>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: ${({ pathname }) => (pathname === '/portfolio/' ? '4.6em auto 0' : '4.6em auto 7em')};

  @media screen and (min-width: 850px) {
    flex-flow: row wrap;

    > a:last-of-type {
      ${({ centerLastCaseStudy }) =>
        centerLastCaseStudy &&
        css`
          position: relative;
          left: 25%;
        `};
    }
  }
`;

const BackgroundImg = styled(Img)`
  > * {
    &:first-child {
      padding-bottom: 56.25% !important;
    }
  }
`;

const CaseStudyPreview = styled(Link)`
  height: 100%;
  max-height: 476px;
  max-width: 848px;
  position: relative;
  width: 100%;

  @media screen and (min-width: 850px) {
    width: 50%;
    max-width: 719px;
  }
`;

const CaseStudyDetails = styled.div`
  position: absolute;
  bottom: 0.75em;
  left: 0.75em;

  h2,
  p {
    color: #fff;
  }

  @media screen and (min-width: 450px) {
    bottom: 1.5em;
    left: 1.5em;
  }
`;

const Underlay = styled.div`
  background-color: rgba(0, 0, 0, 0.425);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const ClientName = styled.p`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.4em;
  letter-spacing: 0.04em;

  @media screen and (min-width: 450px) {
    font-size: 1.125rem;
  }
`;

const CampaignName = styled.h2`
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.025em;

  @media screen and (min-width: 450px) {
    font-size: 1.938rem;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(CaseStudyPreviews);
