import * as React from 'react';

function SvgColouredLeftArrowIcon(props) {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        cx={16}
        cy={16}
        r={16}
        transform="rotate(-90 16 16)"
        fill="url(#coloured-left-arrow-icon_svg__paint0_linear)"
      />
      <path d="M19.333 22.667l-6.6-6.6 6.6-6.6" stroke="#fff" strokeWidth={2} />
      <defs>
        <linearGradient
          id="coloured-left-arrow-icon_svg__paint0_linear"
          x1={32}
          y1={-6}
          x2={-11}
          y2={53.5}
          gradientUnits="userSpaceOnUse">
          <stop offset={0.234} stopColor="#E4097E" />
          <stop offset={1} stopColor="#FFEF00" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgColouredLeftArrowIcon;
