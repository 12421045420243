import React, { PureComponent, createRef } from 'react';
import { isIOS, isSafari } from 'react-device-detect';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Mailbox from 'images/Shared/NewsletterSignUp/Mailbox';
import styled, { css } from 'styled-components';

class NewsletterSignUp extends PureComponent {
  state = {};

  callToActionTextRef = createRef();

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    addToMailchimp(this.state.email)
      .then((data) => {
        this.callToActionTextRef.current.style.opacity = 0;
        if (data.result === 'success') {
          form.innerHTML =
            "<p style='color: #0095cd; font-size: 1.4rem;'>Thanks - we'll keep you posted!</p>";
        } else if (data.result === 'error') {
          form.innerHTML =
            "<p style='color: #0095cd; font-size: 1.4rem;'>Error! Please refresh and try again.</p>";
        }
      })
      .catch((error) => {
        if (error) {
          this.callToActionTextRef.current.style.opacity = 0;
          form.innerHTML =
            "<p style='color: #0095cd; font-size: 1.4rem;'>Error! Please check your connection,<br />refresh, and try again.</p>";
        }
      });
  };

  render() {
    const { email } = this.state;
    return (
      <Wrapper>
        <StyledMailbox />
        <TextAndFormWrapper>
          <H2AndGradientHRWrapper>
            <H2>Newsletter</H2>
            <GradientHR />
          </H2AndGradientHRWrapper>
          <Text ref={this.callToActionTextRef}>
            Get the best video strategy news and updates once
            <br />a month straight to your inbox!
          </Text>
          <Form
            name="newsletter"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}>
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <Input type="hidden" name="form-name" value="newsletter" />
            <div hidden>
              <Label>
                Don’t fill this out:
                <Input name="bot-field" onChange={this.handleChange} />
              </Label>
            </div>
            <GradientBorder isIOS={isIOS} isSafari={isSafari}>
              <Input
                placeholder="Enter your email"
                type="email"
                name="email"
                aria-label="Enter your email"
                onChange={this.handleChange}
                value={email}
                required
              />
            </GradientBorder>
            <SubscribeButton type="submit">Subscribe</SubscribeButton>
          </Form>
        </TextAndFormWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.section`
  margin: -3.375em auto 5em;

  @media screen and (min-width: 750px) {
    display: flex;
    justify-content: space-around;
    margin: 0 auto 9.938em;
  }

  @media screen and (min-width: 800px) {
    justify-content: space-between;
    max-width: 730px;
  }

  @media screen and (min-width: 1150px) {
    max-width: 940px;
  }
`;

const StyledMailbox = styled(Mailbox)`
  @media screen and (max-width: 749px) {
    left: 50%;
    margin-left: -123px;
    opacity: 0.2;
    position: absolute;
    z-index: -1;
  }
`;

const TextAndFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 326px;
  justify-content: space-evenly;
  margin: 0 2.5%;
  max-width: 410px;

  @media screen and (min-width: 420px) {
    margin: 0 auto;
  }

  @media screen and (min-width: 750px) {
    justify-content: space-between;
    margin: 0;
  }

  @media screen and (min-width: 1150px) {
    max-width: 580px;
  }
`;

const H2AndGradientHRWrapper = styled.div`
  align-self: center;

  @media screen and (min-width: 750px) {
    align-self: flex-start;
  }
`;

const GradientHR = styled.div`
  background: linear-gradient(262.46deg, #e4097e 26.97%, #ffef00 122.99%);
  height: 4px;
`;

const H2 = styled.h2`
  font-size: calc(1.65rem + 2vw);
  font-weight: 700;
  padding-bottom: 0.065em;

  @media screen and (min-width: 1200px) {
    font-size: 3rem;
  }
`;

const Text = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;

  @media screen and (min-width: 750px) {
    text-align: left;
  }
`;

const GradientBorder = styled.div`
  ${({ isIOS, isSafari }) => !isIOS && !isSafari && css`
    background: linear-gradient(262.46deg, #e4097e 26.97%, #ffef00 122.99%);
    padding: 2px;
  `}
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label``;

const Input = styled.input`
  background-color: #f4f4f4;
  border-radius: 0.125em;
  border: 1px solid #f4f4f4;
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 0.01em;
  padding: 0.5em;
  width: 100%;

  &::placeholder {
    color: #c4c4c4;
    font-style: italic;
  }
`;

const SubscribeButton = styled.button`
  align-self: center;
  background: linear-gradient(262.46deg, #e4097e 26.97%, #ffef00 122.99%);
  border-radius: 1.5em;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 1.313em;
  padding: 0.5em 1.5em;

  @media screen and (min-width: 750px) {
    align-self: flex-start;
  }
`;

export default NewsletterSignUp;
